import React, {useEffect, useState} from 'react'
import './data.css'
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import {request} from "../Data/Requests";

export default function Information(props) {

    const [client, setClient] = useState(null);
    const [clients, setClients] = useState([]);
    const [products, setProducts] = useState([]);
    const [search, setSearch] = useState('');

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const monthNames = [
            'ene',
            'feb',
            'mar',
            'abr',
            'may',
            'jun',
            'jul',
            'ago',
            'sep',
            'oct',
            'nov',
            'dic',
        ];
        const monthName = monthNames[date.getMonth()];
        return `${day} ${monthName}`;
    };

    useEffect(() => {
        request('https://meg-gt.improgress.net/req/request/clients').then((response) => {
            const _clients = response.clients.map((client, index) => {
                return {value: index, label: client};
            });

            setClients(_clients);
        });
    }, []);

    useEffect(() => {
        if (client) {
            request(`https://meg-gt.improgress.net/req/filter/plan/client=${client}`).then(
                (response) => {
                    setProducts(response);
                }
            );
        }
    }, [client]);


    return (
        <>
            <Modal show={props.show} onHide={props.handleClose} backdrop='static' keyboard={false} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Búsqueda de producción por cliente</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Select
                        options={clients}
                        onChange={(e) => setClient(e.label)}
                        placeholder='Seleccione un cliente...'
                    />
                    <input
                        type='text'
                        className='form-control mt-3'
                        placeholder='Buscar por código o producto...'
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />

                    {products.length > 0 && (
                        <div className='table-responsive vh-70'>
                            <table className='table'>
                                <thead>
                                <tr>
                                    <th scope='col'>OPP</th>
                                    <th scope='col'>Código</th>
                                    <th scope='col'>Producto</th>
                                    <th scope='col'>Color</th>
                                    <th scope='col'>Cantidad</th>
                                    <th scope='col'>Inicio</th>
                                    <th scope='col'>Fin</th>
                                    <th scope='col'>Entrega</th>
                                </tr>
                                </thead>
                                <tbody className='table-group-divider'>
                                {products
                                    .filter(
                                        (product) =>
                                            product.itemid.includes(search) ||
                                            (product.productname &&
                                                product.productname
                                                    .toLowerCase()
                                                    .includes(search.toLowerCase()))
                                    )
                                    .map((product, index) => (
                                        <tr key={index}>
                                            <th scope='row'>{product.opp}</th>
                                            <td>{product.itemid}</td>
                                            <td>{product.productname}</td>
                                            <td>{product.descripcion_clordescripcion}</td>
                                            <td>{product.lote}</td>
                                            <td>{formatDate(product.start_time)}</td>
                                            <td>{formatDate(product.end_time)}</td>
                                            <td>
                                                {product.fecha_compromiso
                                                    ? formatDate(product.fecha_compromiso)
                                                    : '---'}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}

                </Modal.Body>
            </Modal>
        </>
    );
}
