import React, {useEffect, useState} from 'react'
import './data.css'
import Modal from "react-bootstrap/Modal";
import {requestpost} from "../Data/Requests";

export default function Calculate(props) {

    const [code, setCode] = useState("");
    const [quantity, setQuantity] = useState("");
    const [data, setData] = useState(null);


    const [errors, setErrors] = useState({code: "", quantity: ""});

    const [errorMessage, setErrorMessage] = useState("");

    const validateFields = () => {
        let valid = true;
        let _errors = { code: "", quantity: "" };

        if (code === "") {
            _errors.code = "Código de artículo requerido";
            valid = false;
        }

        if (quantity === "" || quantity <= 0) {
            _errors.quantity = "La cantidad tiene que ser mayor a 0";
            valid = false;
        }

        setErrors(_errors);
        return valid;
    };

    const searchProducts = () => {
        if (validateFields()) {
            requestpost(`https://meg-gt.improgress.net/api/consult_proy?articulo=${code}&cantidad=${quantity}`)
                .then((response) => {
                    if (
                        response.status === 500 ||
                        response.data == null ||
                        response.data.length === 0
                    ) {
                        setErrorMessage("Código no encontrado");
                    } else {
                        setData(response);
                        setErrorMessage("");
                    }
                })
                .catch((error) => {
                    setErrorMessage("Código no encontrado");
                });
        }
    };

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        const options = { day: "2-digit", month: "short" };
        return date.toLocaleDateString("es-ES", options);
    };

    const handleClose = () => {
        setCode("");
        setQuantity("");
        setData(null);
        setErrors({ code: "", quantity: "" });
        setErrorMessage("");
        props.setShow(false)
    }

    return (<>
        <Modal
            show={props.show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <strong> Cálculo de fecha de entrega de productos </strong>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="w-100 row row-cols-1 row-cols-md-3">
                        <div className="">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Código de producto"
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                            />
                            {errors.code && (
                                <span style={{ color: "red" }}>{errors.code}</span>
                            )}
                        </div>
                        <div className="">
                            <div className="mb-3 w-100 mt-3 mt-md-0 ">
                                <div>
                                    <input
                                        type="number"
                                        className="form-control"
                                        onChange={(e) => setQuantity(e.target.value)}
                                        placeholder="Cantiddad"
                                        aria-label="Cantidad"
                                        aria-describedby="product-quantity"
                                    />
                                    {errors.quantity && (
                                        <div style={{ color: "red" }}>{errors.quantity}</div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <button
                                className="btn btn-outline-secondary w-100"
                                type="button"
                                id="btn-search-product"
                                onClick={searchProducts}
                            >
                                Buscar
                            </button>
                        </div>
                    </div>

                    {data && (
                        <div>
                            <h3>Partes necesarias</h3>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th className="text-center" scope="col">Parte</th>
                                    <th className="text-center" scope="col">Maquina</th>
                                    <th className="text-center" scope="col">Ciclo</th>
                                    <th className="text-center" scope="col">Fecha de inicio</th>
                                </tr>
                                </thead>
                                <tbody>
                                {data.data.map((item, index) => (
                                    <tr key={index}>
                                        <td className="text-center">{item.parte}</td>
                                        <td className="text-center">{item.maquina}</td>
                                        <td className="text-center">{item.ciclo}</td>
                                        <td className="text-center">
                                            {formatDate(item.end_time)}
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            <p>
                                Fecha estimada de fin de producción:{" "}
                                {formatDate(data.fecha_estimada[0])}
                            </p>
                            <p>
                                Nota: {data.dias_adicionales[0]} días adicionales necesarios
                                para procesos post producción.
                            </p>
                        </div>
                    )}
                </div>
            </Modal.Body>
        </Modal>
        </>);
}
