import React, {useEffect} from 'react'
import './suggest.css'
import * as XLSX from 'xlsx';
// import sugest from './df_sugerido_costo.json'
import { Navbar } from '../Navbar/Navbar';
import Swal from 'sweetalert2';
import {request} from '../Data/Requests'
import { set } from 'date-fns';

const Suggest = () => {
  const [sugest, setSugest] = React.useState([]);

  console.log('suggest')

  useEffect(() => {
    Swal.fire({
      title: 'Procesando',
      text: 'Por favor espere...',
      icon: 'info',
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });
    // requestpost(`http://meg-gt.improgress.net/api/consult_pesos_inventario`).then(
    request(`https://meg-gt.improgress.net/api/suggested_production`).then(
      (response) => {
       setSugest(response);
        // setPesosData(response[0]);
        console.log("suggesting API", JSON.stringify(response));
        // TODO DELETE
        Swal.close();

        Swal.fire({
          title: 'Respuesta:',
          // text: JSON.stringify(response.message),
          icon: 'success',
        });
      })
      
  }, []);

  const downloadCatalog = () => {
    const worksheet = XLSX.utils.json_to_sheet(sugest);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sugest');
        XLSX.writeFile(workbook, 'Sugest.xlsx');
  }

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-GT', { style: 'currency', currency: 'GTQ' }).format(value);
  };

  const formatDecimal = (value) => {
    return value.toFixed(0);
  };
  
  return (
    <div>
      <Navbar />
            <button onClick={() => downloadCatalog()} className='botonDescargaSug' style={{marginTop: '.5rem'}}>Descargar</button>
       <div className='tableContainerSugest'>
       <table className="catalogTable">
      <thead>
        <tr>
          
         {/* <th className="specialColumn">sku_parte</th> */}
         {/* <th className="specialColumn">sku_parte_sin_material</th> */}
         {/* <th className="specialColumn">MA</th> */}
          <th className="specialColumn">sku</th>
          <th className="specialColumn">Color</th>
          {/* <th>Sede </th> */}
          <th className="specialColumn">Producto</th>
          <th className="specialColumn">nivel_max</th>
          {/* <th className="specialColumn">produccion_un_dia</th> */}
          <th className="specialColumn">nivel_min</th>
          <th className="specialColumn">OPP</th>
          <th className="specialColumn">Maquina</th>
          <th className="specialColumn">Prioridad en MQ</th>
          <th className="specialColumn">inventario</th>
          {/* <th className="specialColumn">tipo</th> */}
          {/* <th className="specialColumn">OPP</th> */}
          {/* <th className="specialColumn">saldoxOPP</th> */}
          {/* <th className="specialColumn">cantidad_solicitada</th> */}

          {/* <th className="specialColumn">pendiente_produccion</th> */}
          <th className="specialColumn">sugerido</th>
          <th className="specialColumn">Beneficio</th>
         
          {/* <th>Zona <button onClick={() => sortData('ZONA')}>Sort</button>
            <input type="text" placeholder="Filter" onChange={(e) => handleFilterChange(e, 'ZONA')} /></th> */}
        </tr>
      </thead>
      <tbody>
        {/* {catalogFirst.map((item, index) => ( */}
        
        {sugest.map((item, index) => (
          <tr key={index}>
            
         
            {/* <td className="specialColumn">{item.sku_parte}</td> */}
            {/* <td className="specialColumn">{item.sku_parte_sin_material}</td> */}
            {/* <td className="specialColumn">{item.MA}</td> */}
            <td className="specialColumn">{item.sku}</td>
            <td className="specialColumn">{item.color}</td>
            <td className="specialColumn" style={{width: '16rem'}}>{item.Producto}</td>
            <td className="specialColumn">{item.nivel_max}</td>
            {/* <td className="specialColumn">{item.produccion_un_dia}</td> */}
            <td className="specialColumn">{item.nivel_min ? formatDecimal(item.nivel_min): ''}</td>
            <td className="specialColumn">{item.OPP}</td>
            <td className="specialColumn">{item.MA}</td>
            <td className="specialColumn">{item.PRIORITY_MACHINE}</td>
            <td className="specialColumn">{item.inventario}</td>
            {/* <td className="specialColumn">{item.tipo}</td>
            <td className="specialColumn">{item.OPP}</td>
            <td className="specialColumn">{item.saldoxOPP}</td>
            
            
            <td className="specialColumn">{item.cantidad_solicitada}</td>
            <td className="specialColumn">{item.pendiente_produccion}</td> */}
            <td className="specialColumn">{item.sugerido}</td>
            <td className="specialColumn">{item.Beneficio ? formatCurrency(item.Beneficio) : ''}
</td>
            {/* <td className="specialColumn">{item.Prioridad_en_Maquina}</td> */}
            {/* <td>{zonaMapping[item.ZONA]|| item.ZONA}</td> */}
            
          </tr>
        ))}
      </tbody>
    </table>
    </div>
    </div>
  )
}

export default Suggest