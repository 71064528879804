import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/js/bootstrap.bundle';
import Login from './Login/Login';
import '../styles/app.scss';
import ProtectedRoutes from './ProtectedRoutes';
import Workload from './Workload/Workload';
import MegaSales from './Sales/MegaSales';
import MegaGraph from './Graphs/MegaGraph';
import Margin from './Graphs/Margin';
// import GetData from './Data/GetData';
import { registerLicense } from '@syncfusion/ej2-base';
import MachineOnline from './Workload/UpdateMachines/MachineOnline';
import Landing from './Landing/Landing';
import Exceptions from './Workload/UpdateMachines/Exceptions';
import Suggest from './Suggest/Suggest';
import SanityChk from './Sanity/SanityChk';

// registerLicense('Ngo9BigBOggjHTQxAR8/V1NGaF5cXmdCdkx1WmFZfV1gdVVMZVlbQX5PMyBoS35RdUVkWXtfcHFTRmBeUkV3');
registerLicense('Ngo9BigBOggjHTQxAR8/V1NGaF1cWmhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEZjUX9dcXNURWVUUkJzXQ==');


function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/MachineList" element={<ProtectedRoutes><MachineOnline /></ProtectedRoutes>} />
          <Route path="/exception" element={<ProtectedRoutes><Exceptions /></ProtectedRoutes>} />
          <Route path='/home' element={<ProtectedRoutes><Landing /></ProtectedRoutes>} />
          <Route path="/sales" element={<ProtectedRoutes><MegaSales /></ProtectedRoutes>} />
          <Route path="/graphs" element={<ProtectedRoutes><MegaGraph /></ProtectedRoutes>} />
          <Route path="/margin" element={<ProtectedRoutes><Margin /></ProtectedRoutes>} />
          <Route path="/sugest" element={<ProtectedRoutes><Suggest /></ProtectedRoutes>} />
          <Route path="/sanity" element={<ProtectedRoutes><SanityChk /></ProtectedRoutes>} />
          {/* <Route path="/home" element={<ProtectedRoutes><GetData/> </ProtectedRoutes> } /> */}
          <Route path="/gantt" element={<ProtectedRoutes><Workload /> </ProtectedRoutes> } />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
