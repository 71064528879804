import Users from "./db.json";



const AuthenticateUser = ({username, password}) => {
  const users = Users;
  let authorizedUser = null;

  users.forEach((user) => {
    if(user.username === username && user.password === password){
      authorizedUser = user;
    }
  });

  return authorizedUser;
};

export default AuthenticateUser;