import React from 'react'
import { Navigate } from 'react-router-dom';

function ProtectedRoutes({children}) {
  const user = window.localStorage.getItem('user');

    if (user == null) {
        return <Navigate to="/" />;
    }

    return children;
}

export default ProtectedRoutes;