import React, {useEffect, useState} from 'react'
import './data.css'
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import {request, requestpost} from "../Data/Requests";

export default function Warehouse(props) {

    const [warehouse, setWarehouse] = useState('');
    const [wlabel, setWlabel] = useState(true);
    const [warehouses, setWarehouses] = useState([]);
    const [code, setCode] = useState(null);
    const [codes, setCodes] = useState([]);
    const [inventory, setInventory] = useState([]);
    const [products, setProducts] = useState([])
    const [all, setAll] = useState([])

    useEffect(() => {
        requestpost('https://meg-gt.improgress.net/api/gen_inventory2?').then((response) => {
            const _inventory = response[0];
            setInventory(_inventory);
            // console.log('Inventory:', _inventory);
            // TODO DELETE

            const _warehouses = [
                'Todos los almacenes',
                ...new Set(_inventory.map((item) => item.almacen)),
            ];

            const _products_code = Array.from(new Set(_inventory.map((product) => (product.codigo_de_articulo))))
            const _codes = _products_code.map((code, index) => {
                return {value: index, label: code};
            });

            setWarehouses(_warehouses);
            setCodes(_codes)
            // console.log('Warehouses:', _warehouses);
            // console.log('Codes:', _codes);
            // TODO DELETE
        });
    }, []);

    useEffect(() => {
        const _products = all.filter((product) => (product.codigo_de_articulo === code));

        setProducts(_products)

    }, [all, code])

    const handleWarehouse = (e) => {
        setWarehouse(e.target.value)
        let _all;

        //get the products on this warehouse
        if (e.target.value !== 'Todos los almacenes') {
            _all = inventory.filter((product) => (product.almacen === e.target.value))
            setWlabel(false);
        } else {
            _all = inventory
            setWlabel(true);
        }

        setAll(_all)
    }

    const handleClose = () => {
        setAll([])
        setProducts([])
        setCode(null)
        props.setShow(false)
    }

    return (
        <>
            <Modal show={props.show} onHide={handleClose} backdrop='static' keyboard={false} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Búsqueda de producto por almacén</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <select className='form-select me-3 w-100' onChange={handleWarehouse}>
                        <option defaultValue hidden>Seleccione un almacen...</option>
                        {warehouses.map((warehouse, index) => (
                            <option key={index} value={warehouse}>
                                {warehouse}
                            </option>
                        ))}
                    </select>
                    {/* <Select className='w-50' options={codes} onChange={(e) => setCode(e.label)} /> */}
                    <Select
                        className='w-100 mt-3'
                        options={codes}
                        onChange={(e) => setCode(e.label)}
                        noOptionsMessage={() => "Artículo sin existencia"}
                    />

                    {
                        products.length > 0 ?
                            <div key={warehouse}>
                                <h3 className='my-2'>Almacen: {warehouse}</h3>
                                <div className='table-responsive vh-70'>
                                    <table className='table'>
                                        <thead>
                                        <tr>
                                            <th>Código</th>
                                            <th>Producto</th>
                                            <th>Color</th>
                                            {wlabel ? <th>Almacen</th> : null}
                                            <th>Estilo</th>
                                            <th>Configuracion</th>
                                            <th>Ciclo</th>
                                            <th>Cantidad</th>
                                        </tr>
                                        </thead>
                                        <tbody className='table-group-divider'>
                                        {products.filter(p => p.almacen !== "produccion").map((product, index) => (
                                            <tr key={index}>
                                                <th scope='row'>{product.codigo_de_articulo}</th>
                                                <td>{product.nombre_del_producto}</td>
                                                <td>{product.color}</td>
                                                {wlabel ? <td>{product.almacen}</td> : null}
                                                <td>{product.estilo}</td>
                                                <td>{product.configuracion}</td>
                                                <td>{product.ciclo}</td>
                                                <td>{product.OnHandQuantity}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    <div className='enProduccionDiv'>
                                        <h3 className='my-2'>En Producción</h3>
                                        <table className='table'>
                                            <thead>
                                            <tr>
                                                <th>Código</th>
                                                <th>Producto</th>
                                                <th>Color</th>
                                                <th>Máquina</th>
                                                <th>Inicio</th>
                                                <th>Final</th>
                                                <th>Cantidad</th>
                                            </tr>
                                            </thead>
                                            <tbody className='table-group-divider'>
                                            {products.filter(p => p.almacen === "produccion").map((product, index) => ( // MODIFICACIÓN
                                                <tr key={index}>
                                                    <th scope='row'>{product.codigo_de_articulo}</th>
                                                    <td>{product.nombre_del_producto}</td>
                                                    <td>{product.color}</td>
                                                    <td>{product.machine}</td>
                                                    <td>{new Date(product.start_time).toLocaleDateString('es-ES', {
                                                        day: '2-digit',
                                                        month: 'short'
                                                    })}</td>
                                                    <td>{new Date(product.end_time).toLocaleDateString('es-ES', {
                                                        day: '2-digit',
                                                        month: 'short'
                                                    })}</td>
                                                    <td>{product.lote}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            : code ?
                                <h3 className='my-2'>El producto actualmente no se encuentra en existencia</h3> : null
                    }
                </Modal.Body>
            </Modal>
        </>
    );
}
