// import React from 'react'
import React, { useEffect, useState } from 'react';
import './login.scss'
import LogInForm from './LogInForm'

function Login() {
    const [showForm, setShowForm] = useState(false);
    const [titleZoom, setTitleZoom] = useState(false);
    const [titleMove, setTitleMove] = useState(false);
  
    useEffect(() => {
      const timer1 = setTimeout(() => {
        setTitleZoom(true);
      }, 0); // Start zooming out immediately
  
      const timer2 = setTimeout(() => {
        setTitleMove(true);
      }, 2000); // Set the time to move the title to the top
  
      const timer3 = setTimeout(() => {
        setShowForm(true);
      }, 3000); // Existing delay to show login form
      return () => {
        clearTimeout(timer1);
        clearTimeout(timer2);
        clearTimeout(timer3);
      };

    }, []);
    return (
      <div className="min-vh-100 d-flex justify-content-center align-items-center bkg">
      <div className="video-container">
        <video src="./images/intro2.mp4" autoPlay loop muted></video>
      </div>
      <div className={`app-title ${titleZoom ? "zoom-out" : ""} ${titleMove ? "move-to-top" : ""}`}>
        Mega Producción
      </div>
        <div className={`form-container d-flex flex-column ${showForm ? 'show zoom-in' : 'delay'}`}>
                <img src='./images/powerBy2.png' className={'align-self-center w-50 mb-4'} alt='logo improgress'/>
                <h5 className={'text-center'}>Ingresa tus credenciales para iniciar sesión</h5>
                <span className={'divider'}>○</span>
                <LogInForm />
                <div className='mt-3'>
                    <span className={'divider'}></span>
                    <div className='w-100 d-flex justify-content-center col-11 pt-4'>
                        {/* <button className='w-100 btn btn-outline-secondary py-2 justify-self-end'> */}
                            {/* <Link to={"/losses"} className='w-100 btn btn-outline-secondary py-2 justify-self-end'> Registro de venta perdida</Link> */}
                        {/* </button> */}
                    </div>
                </div>
            <div>
                <p className='versionText'>
                    v 1.1.0
                </p>
            </div>
            </div>
        </div>

  )
}

export default Login