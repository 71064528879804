import React, {useEffect, useState} from "react";
import "./data.css";
import {Navbar} from "../Navbar/Navbar";
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {request, requestpost} from "../Data/Requests";
import Warehouse from "./Warehouse";
import Information from "./Information";
import Calculate from "./Calculate";
import {useNavigate} from "react-router-dom";

const WorkloadMobile = () => {

    const [information, setInformation] = useState({
        "mold_hours": 0,
        "opp_count": 0,
    });
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [showCalculate, setShowCalculate] = useState(false);
    const [showWarehouse, setShowWarehouse] = useState(false);
    const [weight, setWeight] = useState([]);

    const handleClose = () => {
        setShow(false);
    };

    const parseHours = (time) => {
        let minutes = "0."+time.toString().split(".")[1];
        minutes = (parseFloat(minutes) * 60).toFixed(0);
        return parseInt(time) + " horas " + minutes + " minutos";
    }


    useEffect(() => {
        request("https://meg-gt.improgress.net/req/request/get_information").then(response => {
            setInformation(response);
        })

        request("https://meg-gt.improgress.net/req/request/weight").then(response => {
            setWeight(response);
        })
    }, []);

    return (<div>
            <Navbar/>
            {/* LANDING PAGE */}
            <Warehouse show={showWarehouse} setShow={setShowWarehouse} />
            <Information show={show} handleClose={handleClose} />
            <Calculate show={showCalculate} setShow={setShowCalculate} />

        <div className='m-3'>

            <button className='btn btn-outline-primary w-100 mb-2' onClick={() => navigate('/sales')}>Ingresar nuevo pedido</button>
            <button className='btn btn-outline-secondary w-100 mb-2' onClick={() => setShowCalculate(true)}> Calcular fecha de entrega</button>
            <div className='d-flex gap-2 w-100 mb-2 justify-content-between'>
                <button className='btn btn-outline-primary w-50' onClick={() => setShowWarehouse(true)}>Inventario</button>
                <button className='btn btn-outline-primary w-50' onClick={() => setShow(true)}>Consultar órdenes</button>
            </div>
                {/* DATA */}
                <div className='mt-4'>
                    <small className='fw-semibold text-muted blockquote-footer'>INFORMACIÓN GENERAL</small>
                    <div className="card text-bg-light border-0 my-2 shadow">
                        <div className="card-body">
                            {/* MOLD HOURS */}
                            <div className='d-flex'>
                                {/* LOGO */}
                                <div className='bg-primary rounded me-3 px-2'>
                                    <span className="material-symbols-outlined display-4 text-light mt-1">cached</span>
                                </div>
                                <div>
                                    <small className="text-muted">Tiempo de cambio del molde</small>
                                    {/* PARSE THIS TO HOURS - MINUTES */}
                                    <p className="fw-semibold m-0">{information.mold_hours > 0 ?
                                        parseHours(information.mold_hours) : 0}</p>
                                </div>
                            </div>
                            {/* # OPP   */}
                            <div className='d-flex mt-3'>
                                {/* LOGO */}
                                <div className='bg-secondary rounded me-3 px-2'>
                                    <span className="material-symbols-outlined display-4 text-light mt-1">fact_check</span>
                                </div>
                                <div>
                                    <small className="text-muted text-center">Cantidad de OPP</small>
                                    {/* PARSE THIS TO HOURS - MINUTES */}
                                    <p className="fw-semibold m-0">{information.opp_count}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* PRODUCED KG  */}
                    <small className='fw-semibold text-muted blockquote-footer'>PRODUCCIÓN TOTAL PLANIFICADA</small>
                    <h3 className="fw-bold ms-3">{parseInt(weight.total).toLocaleString("es-GT")} kg</h3>
                    {/* PRODUCED CARDS */}
                    <div className='row row-cols-2 g-2'>
                        <div className='col'>
                            <div className="card" style={{backgroundColor: "#003865"}}>
                                <div className='card-body text-center text-light'>
                                    <small>STOCK</small>
                                    <h3 className="fw-semibold m-0">{weight.stock_percent}%</h3>
                                    <small>{parseInt(weight.stock).toLocaleString("es-GT")}kg</small>
                                </div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className="card" style={{backgroundColor: "#FC4C02"}}>
                                <div className='card-body text-center text-light'>
                                    <small>STOCKOUT</small>
                                    <h3 className="fw-semibold m-0">{weight.stockout_percent}%</h3>
                                    <small>{parseInt(weight.stockout).toLocaleString("es-GT")}kg</small>
                                </div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className="card" style={{backgroundColor: "#FC4C02"}}>
                                <div className='card-body text-center text-light'>
                                    <small>ORDEN</small>
                                    <h3 className="fw-semibold m-0">{weight.orden_percent}%</h3>
                                    <small>{parseInt(weight.orden).toLocaleString("es-GT")}kg</small>
                                </div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className="col card" style={{backgroundColor: "#003865"}}>
                                <div className='card-body text-center text-light'>
                                    <small>MÁQUINA</small>
                                    <h3 className="fw-semibold m-0">{weight.maquina_percent}%</h3>
                                    <small>{parseInt(weight.maquina).toLocaleString("es-GT")}kg</small>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>);
};
export default WorkloadMobile;
