import React, { useEffect, useState } from 'react';
import { requestpost, request } from '../../Utils/Requests';
import swal from 'sweetalert';
import { Navbar } from '../../Navbar/Navbar';
import '../../Landing/landing.css'

const Exceptions = () => {
  const [selectedMachine, setSelectedMachine] = useState('');
  const [prodcode, setProdcode] = useState('');
  const [msg, setMsg] = useState('');
  const [machineList, setMachineList] = useState([]);

  
  useEffect(() => {
    request('https://meg-gt.improgress.net/api/consult_status_machine').then((response) => {
      // console.log('Machine List:', response);
      setMachineList((response).sort((a, b) => a.maquina.localeCompare(b.maquina)));
    });
  }, []);

  // Handle select change
  const handleMachineChange = (event) => {
    setSelectedMachine(event.target.value);
  };

  // Handle prodcode input change
  const handleProdcodeChange = (event) => {
    setProdcode(event.target.value);
  };

  // Handle message input change
  const handleMessageChange = (event) => {
    setMsg(event.target.value.slice(0, 100));
  };

  // Function to handle form submission
   const handleSubmit = (event) => {
       event.preventDefault();
       const user = localStorage.getItem('user');
       let _response = ""
       let error = false;

       request(`https://meg-gt.improgress.net/api/exceptions?usuario=${user}&machine=${selectedMachine}&code=${prodcode}&message=${msg}`)
           .then(async (response) => {
              //  console.log('Submit Response:', response.message);
               //alert(response.message);
               await swal(response.message[0], '', 'success');
               setSelectedMachine('');
               setProdcode('');
               setMsg('');
           })
           .catch(async (error) => {
               console.error('Submit Error:', error);
               await swal(error, '', 'error');

           });

   };
   
   
   const handleSubmitUpdate = (event) => {
       event.preventDefault();
       const user = localStorage.getItem('user');
       let _response = ""
       let error = false;

       request(`https://meg-gt.improgress.net/api/run_plan_prod?usuario=${user}&test=false`)
           .then(async (data) =>  {
             const pepito = `${data.message} status es ${data.status}`;
          
          //  console.log(data);
           if (data.status.includes('ok')) {
             let win = window.open(
               'https://meg-gt.improgress.net/req/update',
               '_blank'
             );
   
             let pollTimer = window.setInterval(function () {
               if (win.document.body.textContent === 'null') {
                 win.close();
                 window.clearInterval(pollTimer);
                
   
                 
               }
             }, 700);
           }
           /*setModalMessage(pepito);
         setUpdating(false);*/
          
           await swal(pepito, '', 'success');
           
         })
           .catch(async (error) => {
               console.error('Submit Error:', error);
               await swal(error, '', 'error');

           });

   };


   

  return (
    <div>
      <Navbar />
      <div className='d-flex justify-content-center align-items-center vh-100 bg-primary'>
            <div className='d-flex flex-column container-login justify-content-center'>
                <div className='w-100 d-flex justify-content-center'>
                    <img
                        src='./images/improgressbkg.png'
                        alt='Power By Improgress'
                        style={{width: '18rem'}}
                    />
                </div>

                <span className='divider mb-3'></span>
      <h1>Excepciones</h1>
      <form onSubmit={handleSubmit}>
        <div className='form-floating mb-4'>
          {/* <label htmlFor="machine-select">Máquina:</label> */}
          <select id="machine-select" value={selectedMachine} onChange={handleMachineChange}>
            <option value="">--Por favor escoja una máquina--</option>
            {machineList.map((machine, index) => (
              <option key={index} value={machine.maquina}>
                {machine.maquina}
              </option>
            ))}
          </select>
        </div>
        <div className='form-floating mb-4'>
          
          <input
            id="prodcode-input"
            placeholder='Código de producto'
            style={{ width: '100%' }} 
            type="text"
            value={prodcode}
            onChange={handleProdcodeChange}
          />
        </div>
        <div className='form-floating mb-5 '>
          
          <textarea
            id="msg-input"
            type="text"
            value={msg}
            style={{ width: '100%' }} 
            placeholder='Razon: max 100 caracteres'
            onChange={handleMessageChange}
          />
        </div>
        <button type="submit" className='w-100 btn btn-primary py-2 justify-self-end'>Aplicar excepción</button>
        <span className={'divider'}></span>
        <button type="submit" className='w-100 btn btn-primary py-2 justify-self-end' onClick={handleSubmitUpdate}>Correr Modelo con Excepciones</button>
      </form>
      </div>
      <div className='custom-shape-divider-bottom-1697045891'>
                <svg
                    data-name='Layer 1'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 1200 120'
                    preserveAspectRatio='none'
                >
                    <path
                        d='M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z'
                        opacity='.25'
                        className='shape-fill'
                    ></path>
                    <path
                        d='M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z'
                        opacity='.5'
                        className='shape-fill'
                    ></path>
                    <path
                        d='M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z'
                        className='shape-fill'
                    ></path>
                </svg>
            </div>
            </div>
    </div>
  );
};

export default Exceptions;
