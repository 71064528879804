import React from 'react'
import * as XLSX from 'xlsx';
import { Navbar } from '../Navbar/Navbar';
import sanity from './sanity_check.json'
import './sanityChk.css'

const SanityChk = () => {

  const downloadCatalog = () => {
    const worksheet = XLSX.utils.json_to_sheet(sanity);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sanity');
        XLSX.writeFile(workbook, 'Sanity.xlsx');
  }

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-GT', { style: 'currency', currency: 'GTQ' }).format(value);
  };

  const formatDecimal = (value) => {
    return value.toFixed(0);
  };
  return (
    <div>
      <Navbar />
            <button onClick={() => downloadCatalog()} className='botonDescargaSug' style={{marginTop: '.5rem'}}>Descargar</button>
       <div className='tableContainerSugest'>
       <table className="catalogTable">
      <thead>
        <tr>
          
         {/* <th className="specialColumn">sku_parte</th> */}
         {/* <th className="specialColumn">sku_parte_sin_material</th> */}
         {/* <th className="specialColumn">MA</th> */}
         <th className="specialColumn">OPP</th>
          <th className="specialColumn">sku</th>
          <th className="specialColumn">Color</th>
          {/* <th>Sede </th> */}
          <th className="specialColumn">Descripción</th>
          <th className="specialColumn">configuracion</th>
          <th className="specialColumn">estilo</th>
          <th className="specialColumn">cantidad_solicitada</th>
          <th className="specialColumn">Pendiente de Producir</th>
          <th className="specialColumn">tipo</th>
          <th className="specialColumn">nivel_min</th>
          <th className="specialColumn">nivel_max</th>
          <th className="specialColumn">nivel económico</th>
          <th className="specialColumn">nivel Seleccionado</th>
          <th className="specialColumn">nivel max por molde</th>
          <th className="specialColumn">venta mes 1</th>
          <th className="specialColumn">venta mes 2</th>
          <th className="specialColumn">analisis Demanda</th>
          <th className="specialColumn">inventario Prod Terminado</th>
          <th className="specialColumn">inv disp por partes</th>
          <th className="specialColumn">inv total disp</th>
          <th className="specialColumn">Analisis Inventario</th>
          <th className="specialColumn">echa_venta_mes_1</th>
          <th className="specialColumn">echa_venta_mes_2</th>
        </tr>
      </thead>
      <tbody>
        {/* {catalogFirst.map((item, index) => ( */}
        
        {sanity.map((item, index) => (
          <tr key={index}>
            
         
            {/* <td className="specialColumn">{item.sku_parte}</td> */}
            {/* <td className="specialColumn">{item.sku_parte_sin_material}</td> */}
            <td className="specialColumn">{item.OPP}</td>
            <td className="specialColumn">{item.sku}</td>
            <td className="specialColumn">{item.color}</td>
            <td className="specialColumn" style={{width: '16rem'}}>{item.descripcion}</td>
            <td className="specialColumn">{item.configuracion}</td>
            <td className="specialColumn">{item.estilo}</td>
            {/* <td className="specialColumn">{item.nivel_min ? formatDecimal(item.nivel_min): ''}</td> */}
            <td className="specialColumn">{item.cantidad_solicitada}</td>
            <td className="specialColumn">{item.cantidad_pendiente_de_producir}</td>
            <td className="specialColumn">{item.tipo}</td>
            <td className="specialColumn">{item.nivel_min}</td>
            <td className="specialColumn">{item.nivel_max}</td>
            <td className="specialColumn">{item.nivel_economico}</td>
            
            
            <td className="specialColumn">{item.nivel_seleccionado}</td>
            <td className="specialColumn">{item.nivel_max_por_molde}</td>
            <td className="specialColumn">{item.venta_mes_1}</td>
            <td className="specialColumn">{item.venta_mes_2}</td>
            <td className="specialColumn">{item.analisis_demanda}</td>
            <td className="specialColumn">{item.inventario_producto_terminado}</td>
            <td className="specialColumn">{item.disponible_por_partes}</td>
            <td className="specialColumn">{item.inventario_total_disponible}</td>
            <td className="specialColumn">{item.analisis_inventario}</td>
            <td className="specialColumn">{item.fecha_venta_mes_1}</td>
            <td className="specialColumn">{item.fecha_venta_mes_2}</td>
     
            {/* <td className="specialColumn">{item.Prioridad_en_Maquina}</td> */}
            {/* <td>{zonaMapping[item.ZONA]|| item.ZONA}</td> */}
            
          </tr>
        ))}
      </tbody>
    </table>
    </div>
    </div>
  )
}

export default SanityChk