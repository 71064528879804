import React, { useEffect, useState } from 'react';
import {
  ColumnsDirective,
  GanttComponent,
  ColumnDirective,
  ContextMenu,
  Selection,
  Filter,
  Inject,
  Toolbar,
  ExcelExport,
} from '@syncfusion/ej2-react-gantt';
import {
  // registerLicense,
  L10n,
  setCulture,
  setCurrencyCode,
} from '@syncfusion/ej2-base';
import './data.css';
import { Navbar } from '../Navbar/Navbar';
import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';
// import Select from 'react-select';
// import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { Spinner } from 'react-bootstrap';

import { request } from '../Data/Requests';
import { requestpost } from '../Data/Requests';
// import { te } from 'date-fns/locale';
// import { set } from 'date-fns';
// import moment from 'moment';
import Information from './Information';
import Warehouse from './Warehouse';
import Calculate from './Calculate';
import ProductGraphSingle from './ProductGraphSingle';

const Workload = () => {
  const [information, setInformation] = useState([]);
  const [plan, setPlan] = useState(null);
  const [machines, setMachines] = useState(null);
  const [mappedData, setMappedData] = useState([]);
  const [mappedPlan, setMappedPlan] = useState([]);
  const [mapped, setMapped] = useState([]);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [showWarehouse, setShowWarehouse] = useState(false);
  const [showPGS, setShowPGS] = useState(false);
  const [weight, setWeight] = useState([]);
  const [showInformation, setShowInformation] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  let ganttInstance;
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  // const [inventory, setInventory] = useState([]);
  // const [selectedWarehouse, setSelectedWarehouse] = useState('');
  const [productCode, setProductCode] = useState('');
  // const [productName, setProductName] = useState('');
  // const [isProductSelected, setIsProductSelected] = useState(false);
  // const [selectedWarehouse, setSelectedWarehouse] = useState('All Warehouses');
  const [finalProductCode, setFinalProductCode] = useState('');
  // const [finalProductName, setFinalProductName] = useState('');
  // const [junita, setJunita] = useState('block');

  const [machineInfo, setMachineInfo] = useState([]);
  const [machineInfoModal, setMachineInfoModal] = useState(false);
  const [machineInfoname, setMachineInfoname] = useState('');
  const [machineInfoname2, setMachineInfoname2] = useState('');
  const [machineMarginProd, setMachineMarginProd] = useState(false);
  const [machineMarginModal, setMachineMarginModal] = useState(false);
  const [machineInfoMarginProd, setMachineInfoMarginProd] = useState([]);

  // const [search, setSearch] = useState(true);

  const [code, setCode] = useState('');
  const [quantity, setQuantity] = useState('');
  const [data, setData] = useState(null);
  const [machineRequestModal, setMachineRequestModal] = useState(false);

  const [errors, setErrors] = useState({ code: '', quantity: '' });
  const [errorMessage, setErrorMessage] = useState('');

  const validateFields = () => {
    let valid = true;
    let errors = { code: '', quantity: '' };

    if (code === '') {
      errors.code = 'Código de artículo requerido';
      valid = false;
    }

    if (quantity === '' || quantity <= 0) {
      errors.quantity = 'La cantidad tiene que ser mayor a 0';
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  useEffect(() => {
    // Simulate a network request that takes 30 seconds to complete
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 5000);

    return () => clearTimeout(timer); // cleanup function for useEffect
  }, []);

  function getData() {
    const fetchMachine = async () => {
      try {
        const response = await fetch(
          'https://meg-gt.improgress.net/req/request/machines'
        );
        const data = await response.json();
        if (data.length === 0) {
          retryFetch(30000);
        } else {
          setMachines(data);
          getPlan();
        }
      } catch (err) {
        retryFetch(30000);
        console.error(err);
      }
    };

    fetchMachine();
  }
  //console.log("Plan", plan)
  function getPlan() {
    const fetchPlan = async () => {
      try {
        const response = await fetch(
          'https://meg-gt.improgress.net/req/request/plan/simple'
        );
        //const response = await fetch('http://127.0.0.1/req/request/plan/simple');
        const data = await response.json();
        // console.log("feath", data);
        if (data.length === 0) {
          retryFetch2(30000);
        } else {
          setPlan(data);
          getInformation();
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchPlan();
  }

  function parseDate(date) {
    const timezone = new Date().getTimezoneOffset();
    return new Date(date.valueOf() - timezone * 60 * 1000);
  }

  function getInformation() {
    const fetchInformation = async () => {
      try {
        const response = await fetch(
          'https://meg-gt.improgress.net/req/request/get_information'
        );
        //const response = await fetch('http://127.0.0.1/req/request/plan/simple');
        const data = await response.json();
        if (data.length === 0) {
          retryFetch2(30000);
        } else {
          setInformation(data);
          getWeight();
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchInformation();
  }

  function getWeight() {
    const fetchWeight = async () => {
      try {
        const response = await fetch('https://meg-gt.improgress.net/req/request/weight');
        //const response = await fetch('http://127.0.0.1/req/request/plan/simple');
        const data = await response.json();
        //('PLAN', data);
        if (data.length === 0) {
          retryFetch2(30000);
        } else {
          setWeight(data);
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchWeight();
  }

  const retryFetch = (time) => {
    setTimeout(getData, time);
  };

  const retryFetch2 = (time) => {
    setTimeout(getPlan, time);
  };

  useEffect(() => {
    if (mapped.length === 0 && !machines) {
      retryFetch(500);
    }
  }, []);

  // const fetchdataRequest = () => {
  //   if (validateFields()) {
  //     requestpost(
  //       `https://meg-gt.improgress.net/api/consult_proy?articulo=${code}&cantidad=${quantity}`
  //     )
  //       .then((response) => {
  //         if (
  //           response.status === 500 ||
  //           response.data == null ||
  //           response.data.length === 0
  //         ) {
  //           setErrorMessage('Código no encontrado');
  //         } else {
  //           setData(response);
  //           setErrorMessage('');
  //         }
  //       })
  //       .catch((error) => {
  //         setErrorMessage('Código no encontrado');
  //       });
  //   }
  // };

  useEffect(() => {
    if (machineInfoname) {
      requestpost(
        `https://meg-gt.improgress.net/api/consult_machine_new?machine=${machineInfoname}`
        // `https://meg-gt.improgress.net/api/consult_machine?machine=${machineInfoname}`
      ).then((response) => {
        // console.log("this test", response);
        setMachineInfo(response[0]);
      });
    }
  }, [machineInfoname]);

  useEffect(() => {
    if (machineInfoname2) {
      request(
        `https://meg-gt.improgress.net/api/consult_machine_margin?machine=${machineInfoname2}`
      ).then((response) => {
        response.sort((a, b) => {
          const aClasificacion = parseFloat(a.CLASIFICACION);
          const bClasificacion = parseFloat(b.CLASIFICACION);
          if (isNaN(aClasificacion)) return 1;
          if (isNaN(bClasificacion)) return -1;
          return bClasificacion - aClasificacion;
        });
        setMachineInfoMarginProd(response);
      });
    }
  }, [machineInfoname2]);

  useEffect(() => {
    const css = document.createElement('style');
    css.type = 'text/css';
    css.innerHTML = `.e-footer-content .e-filter-popup.e-control.e-btn.e-lib.e-flmenu-cancelbtn.e-flat { display: none; }`;
    document.head.appendChild(css);
    return () => {
      document.head.removeChild(css);
    };
  }, []);

  useEffect(() => {
    if (machines) {
      const data = machines.map((item, index) => {
        const { start_time, end_time, machine, order, stock, stockout } = item;
        // const timezone = new Date().getTimezoneOffset();
        //let startDate = new Date((new Date(start_time).valueOf()) + (timezone*60*1000));
        //let endDate = new Date((new Date(end_time).valueOf()) + (timezone*60*1000))
        let startDate = parseDate(new Date(start_time));
        let endDate = parseDate(new Date(end_time));
        //let endDate = new Date(end_time + (timezone *60*1000));
        //var startDate2 = new Date(start_time)
        //  .toUTCString()
        //  .toLocaleString("es-MX");
        //var endDate2 = new Date(end_time).toUTCString().toLocaleString("es-MX");
        // const duration1 = (endDate - startDate) / (1000 * 60 * 60 * 24);
        const duration2 = (endDate - startDate) / (1000 * 60 * 60);
        return {
          TaskID: machine,
          Maquina: machine,
          TaskName: '',
          color_maquina: '',
          StartDate: startDate,
          EndDate: endDate,
          Progress: stock, //stock, orden
          Order: order,
          ParentId: null,
          OPP: '',
          OP: '',
          //Duration: duration1,
          Time: duration2,
          Stockout: stockout,
        };
      });
      setMappedData(data);
    }
  }, [machines]);
  useEffect(() => {
    if (plan) {
      const data = plan.map((item, index) => {
        const {
          machine,
          end_date,
          start_date,
          machine_selection,
          production_hours,
          productname,
          itemid,
          opp,
          op,
          estilo,
          configuracion,
         descripcion_color,
          ciclo,
          type,
          mold_hours,
          ineficient_hours,
          start_time,
          end_time,
          client,
          fecha_compromiso,
          lote,
          elem,
        } = item;
        var startDate = new Date(start_time);
        var endDate = new Date(end_time);
        const timezone = new Date().getTimezoneOffset();
        startDate = new Date(start_time + timezone * 60 * 1000);
        endDate = new Date(end_time + timezone * 60 * 1000);
        const date1 = fecha_compromiso
          ? new Date(fecha_compromiso).toLocaleDateString('en-GB')
          : null;
        const production_hours2 = (
          production_hours +
          mold_hours +
          ineficient_hours
        ).toFixed(2);
        const duration = (endDate - startDate) / (1000 * 60 * 60 * 24);
        const progress = (production_hours2 / duration) * 100;
        var time =
          (parseFloat(production_hours) / parseFloat(production_hours2)) * 100;
        time = time.toFixed(2);
        var color_maquina =descripcion_color
          ? productname + ' ' +descripcion_color
          : productname;
        return {
          TaskID: machine + index,
          Maquina: '',
          TaskName: productname,
          itemid: itemid,
          lote: lote,
          StartDate: startDate,
          EndDate: endDate,
          Duration: duration,
          Progress: time,
          OPP: opp,
          OP: op,
          estilo: estilo,
          configuracion: configuracion,
          descripcion_color:descripcion_color,
          color_maquina: color_maquina,
          ciclo: ciclo,
          Type_machine: machine_selection,
          Machine: type,
          ParentId: machine,
          Time: production_hours2,
          TimeMold: mold_hours,
          Client: client,
          Fecha_compromiso: date1,
          Elem: elem,
        };
      });
      setMappedPlan(data);
    }
  }, [plan]);

  useEffect(() => {
    if (mappedData && mappedPlan) {
      const data = mappedData.map((item, index) => {
        const {
          TaskID,
          TaskName,
          itemid,
          StartDate,
          EndDate,
          Duration,
          Progress,
          Type_machine,
          Machine,
          ParentId,
          OPP,
          OP,
          estilo,
          configuracion,
         descripcion_color,
          ciclo,
          color_maquina,
          Time,
          Order,
        } = item;
        const _data = mappedPlan.filter((item2) => item2.ParentId === TaskID);
        // const _OPP = mappedPlan.filter((item3) => item3.OPP === OPP)
        const Duration1 = Duration;
        const Duration2 = Time.toFixed(2) + ' horas';
        return {
          TaskID: TaskID,
          Maquina: TaskID,
          ID: TaskID,
          TaskName: TaskName,
          itemid: itemid,
          StartDate: StartDate,
          EndDate: EndDate,
          Duration: Duration1,
          Progress: Progress,
          Order: Order,
          Type_machine: Type_machine,
          Machine: Machine,
          ParentId: ParentId,
          OPP: '',
          OP: '',
          estilo: estilo,
          configuracion: configuracion,
         descripcion_color:descripcion_color,
          color_maquina: color_maquina,
          ciclo: ciclo,
          subtasks: _data,
          Time: Duration2,
        };
      });
      setMapped(data);
    }
  }, [mappedData, mappedPlan]);
  useEffect(() => {
    if (mappedData && mappedPlan) {
      const data = mappedData.map((item, index) => {
        const {
          TaskID,
          TaskName,
          itemid,
          StartDate,
          EndDate,
          Duration,
          Progress,
          Type_machine,
          Machine,
          ParentId,
          OPP,
          OP,
          estilo,
          configuracion,
         descripcion_color,
          ciclo,
          Time,
          Order,
          Stockout,
          color_maquina,
        } = item;
        const _data = mappedPlan.filter((item2) => item2.ParentId === TaskID);
        // const _OPP = mappedPlan.filter((item3) => item3.OPP === OPP)
        const Duration1 = Duration;
        const Duration2 = Time.toFixed(2) + ' horas';
        return {
          TaskID: TaskID,
          Maquina: TaskID,
          ID: TaskID,
          TaskName: TaskName,
          itemid: itemid,
          StartDate: StartDate,
          EndDate: EndDate,
          Duration: Duration1,
          Progress: Progress,
          Order: Order,
          Stockout: Stockout,
          Type_machine: Type_machine,
          Machine: Machine,
          ParentId: ParentId,
          OPP: '',
          OP: '',
          estilo: estilo,
          configuracion: configuracion,
         descripcion_color:descripcion_color,
          color_maquina: color_maquina,
          ciclo: ciclo,
          subtasks: _data,
          Time: Duration2,
        };
      });
      setMapped(data);
    }
  }, [mappedData, mappedPlan]);
  //console.log(mappedPlan)
  L10n.load(require('@syncfusion/ej2-locale/src/es.json')); // load corresponding culture text
  setCulture('es'); // Change the Grid culture
  setCurrencyCode('EUR'); // Change the currency code

  let taskSettings = {
    id: 'TaskID',
    maquina: 'Maquina',
    name: 'TaskName',
    itemid: 'itemid',
    startDate: 'StartDate',
    endDate: 'EndDate',
    fecha_compromiso: 'Fecha_compromiso',
    duration: 'Duration',
    progress: 'Progress',
    Order: 'Order',
    child: 'subtasks',
    client: 'Client',
    OPP: 'OPP',
    OP: 'OP',
    estilo: 'estilo',
    configuracion: 'configuracion',
   descripcion_color: 'DESCRIPCION_COLOR',
    ciclo: 'ciclo',
    Type_machine: 'type',
    Time: 'Time',
    TimeMold: 'Tiempo de molde',
    color_maquina: 'color_maquina',
  };

  function queryTaskbarInfo(args) {
    if (!args.data.Machine) {
      args.progressBarBgColor = 'black';
      args.taskbarBgColor = 'gray';
    } else if (args.data.Machine === 'ORDEN') {
      args.progressBarBgColor = 'green';
      args.taskbarBgColor = 'green';
    } else if (args.data.Machine === 'STOCK') {
      args.progressBarBgColor = 'blue';
      args.taskbarBgColor = 'blue';
    } else if (args.data.Machine === 'STOCKOUT') {
      args.progressBarBgColor = 'red';
      args.taskbarBgColor = 'red';
    } else if (args.data.Machine === 'MAQUINA') {
      args.progressBarBgColor = 'yellow';
      args.taskbarBgColor = 'yellow';
    }
  }

  function toolTipTemplate(args) {
    // const startDate = new Date(args.StartDate.toString());
    const startDate = parseDate(new Date(args.StartDate));
    const endDate = parseDate(new Date(args.EndDate));
    //const startDate = args.StartDate;
    //const endDate = args.EndDate;

    // Configuración de las opciones
    let options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    };

    // Usar 'es-ES' para español (España), si quieres un español más general puedes usar solo 'es'.
    //new Date().toLocaleDateString(undefined,{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
    const formattedDate = startDate.toLocaleDateString('es-GT', options);

    const formattedDate2 = endDate.toLocaleDateString('es-GT', options);
    var stock;
    var order;
    var machine;
    let stockOut;
    var progress;
    if (!args.parentItem) {
      stock = parseInt(args.Progress);
      order = parseInt(args.taskData.Order);
      stockOut = parseInt(args.taskData.Stockout); // Asegura que StockOut sea al menos 0
      machine = 100 - (stock + order + stockOut);
    }
    return (
      <div className='tool'>
        <div>{args.TaskName ? `Nombre: ` + args.TaskName : ''}</div>
        <div>{args.itemid ? ` id:` + args.itemid : ''} </div>
        <div>{args.Maquina ? ` Máquina: ` + args.Maquina : ''}</div>
        <div>{args.Elem ? ` Elem: ` + args.Elem : ''}</div>
        <div>{args.parentItem ? ` Máquina: ` + args.parentItem.taskId : ''}</div>
        <div>{args.OPP ? ` OPP: ` + args.OPP : ''}</div>
        <div>{args.op ? ` OP: ` + args.op : ''}</div>
        <div>{args.taskData.lote ? ` Lote:` + args.taskData.lote : ''} </div>
        <div>{args.estilo ? ` Estilo: ` + args.estilo : ''}</div>
        <div>
          {args.configuracion ? ` Configuración: ` + args.configuracion : ''}
        </div>
        <div>
          {args.descripcion_color ? ` Color: ` + args.descripcion_color : ''}
        </div>
        <div>{args.ciclo ? ` Ciclo: ` + args.ciclo : ''}</div>
        <div>{args.Client ? ` Cliente: ` + args.Client : ''}</div>
        <div>{startDate ? ` Inicio: ` + formattedDate : ''}</div>
        <div>{endDate ? ` Final: ` + formattedDate2 : ''}</div>
        <div>
          {args.Fecha_compromiso
            ? ` Fecha de compromiso: ` + args.Fecha_compromiso
            : ''}
        </div>
        <div>{args.Machine ? ` Producción: ` + args.Machine : ''}</div>
        <div>{args.Type_machine ? ` Asignación: ` + args.Type_machine : ''}</div>
        <div>{args.Time ? ` Tiempo: ` + args.Time : ''}</div>
        <div>{args.TimeMold ? ` Tiempo de molder: ` + args.TimeMold : ''}</div>
        <div>{order ? ' Order: ' + order + '%' : ''}</div>
        <div>{stock ? ' Stock: ' + stock + '%' : ''}</div>
        <div>{stockOut ? ' StockOut: ' + stockOut + '%' : ''}</div>
        {/* <div>{stockOut !== undefined ? 'StockOut: ' + stockOut + '%' : ''}</div> */}
        <div>{machine ? ' Máquina: ' + machine + '%' : ''}</div>
      </div>
    );
  }

  const handleClose = () => {
    setShow(false);
    setShowWarehouse(false);
    setShowPGS(false);
    setMachineInfoModal(false);
    setFinalProductCode('');
    setProductCode('');
    setMachineRequestModal(false);
    setCode('');
    setQuantity('');
    setData(null);
    setErrors({ code: '', quantity: '' });
    setErrorMessage('');
    setMachineMarginModal(false);
  };

  const tooltipSettings = {
    showTooltip: true,
    taskbar: toolTipTemplate,
  };
  if (ganttInstance) {
    ganttInstance.clearFiltering();
  }

  const testingSpinner = (ganttInstance) => {
    setLoading(true);
    ganttInstance.clearFiltering();

    setTimeout(() => {
      ganttInstance.expandAll();

      setTimeout(() => {
        setLoading(false);
      }, 100);
    }, 100);
  };


  function onCLickToolBar(args) {
    if (ganttInstance) {
      if (args.item.properties.id === 'clearfilter') {
        ganttInstance.clearFiltering();
      } else if (args.item.properties.id === 'colapsedAll') {
        ganttInstance.collapseAll();
      } else if (ganttInstance && args.item.properties.id === 'expandAll') {
        testingSpinner(ganttInstance);
        // ganttInstance.expandAll();
      } else if (args.item.properties.id === 'excelExport') {
        const exportProperties = {
          fileName: 'Planificación.xlsx',
          includeHiddenColumn: true,
        };
        ganttInstance.excelExport(exportProperties);
        //test();
      } else if (args.item.properties.id === 'weekPlan') {
        ganttInstance.timelineSettings = {
          timelineViewMode: 'Week',
          topTier: { unit: 'Week' },
          bottomTier: { unit: 'Day', format: 'dd' },
          timelineUnitSize: 35,
        };
      } else if (args.item.properties.id === 'monthPlan') {
        ganttInstance.timelineSettings = {
          timelineViewMode: 'Month',
          topTier: { unit: 'Month' },
          bottomTier: { unit: 'Week', format: 'dd' },
          timelineUnitSize: 35,
        };
      } else if (args.item.properties.id === 'client') {
        setShow(true);
      } else if (args.item.properties.id === 'inventory') {
        setShowWarehouse(true);
      } else if (args.item.properties.id === 'Product') {
        setShowPGS(true);
      } else if (args.item.properties.id === 'information') {
        setShowInformation(true);
      } else if (args.item.properties.id === 'machineRequestModal') {
        setMachineRequestModal(true);
      }
    }
  }

  // CLIENT FILTER
  if (isLoading) {
    return (
      <div className='splash-screen'>
        <div className='video-background'>
          <video autoPlay loop muted>
            <source
              src={`${process.env.PUBLIC_URL}/images/intro.mp4`}
              type='video/mp4'
            />
          </video>

          <div className='overlay-image'>
            <div className='overlay-text'>
              <h2>Cargando el sistema.......</h2>
              <img src='/images/power_by.png' alt='overlay' />
            </div>
          </div>
        </div>
      </div>
    );
  }

  function test() {
    /** hide by HeaderText */
    // console.log(mappedData);
    // console.log(mappedPlan);
    // console.log(mapped);
    // console.log(information);
    // console.log(weight);
  }

  function show1() {
    /** show by HeaderText */
    ganttInstance.showColumn(['Asignación']);
  }

  function hide() {
    /** hide by HeaderText */
    ganttInstance.hideColumn(['Asignación']);
  }

  function showTime() {
    /** show by HeaderText */
    ganttInstance.showColumn(['Tiempo']);
  }

  function hideTime() {
    /** hide by HeaderText */
    ganttInstance.hideColumn(['Tiempo']);
  }

  const columns = [
    'OPP',
    'OP',
    'Cliente',
    'Nombre',
    'Inicio',
    'Final',
    'Fecha de compromiso',
    'Producción',
    'Asignación',
    'configuracion',
    'DESCRIPCION_COLOR',
    'ciclo',
    'Tiempo',
  ];

  const contextMenuItems = [
    { text: 'Buscar Cliente', target: '.e-content', id: 'searchclient' },
    // { text: "INVENTARIO", target: ".e-content", id: "inventory" },
    { text: 'Maquina Info', target: '.e-content', id: 'Maquina Info' },
    {
      text: 'Margen Prod x Maq',
      target: '.e-content',
      id: 'Margen Prod x Maquina',
    },
    {
      text: 'Hide Column',
      target: '.e-content',
      items: columns.map((column) => ({
        text: column,
        id: 'hide-' + column,
      })),
    },
    {
      text: 'Show Column',
      target: '.e-content',
      items: columns.map((column) => ({
        text: column,
        id: 'show-' + column,
      })),
    },
  ];

  function contextMenuClick(args) {
    //console.log(args);
    const id = args.item.id;
    if (args.item.id === 'searchclient') {
      // si es necesario, aca escribir la logica de seleccion de cliente

      setShow(true);
    } else if (args.item.id === 'inventory') {
      // si es necesario, aca escribir la logica de seleccion de cliente

      setShow2(true);
    } else if (args.item.id === 'hide') {
      // si es necesario, aca escribir la logica de seleccion de cliente

      hide();
    } else if (args.item.id === 'show') {
      // si es necesario, aca escribir la logica de seleccion de cliente

      show1();
    } else if (args.item.id === 'hideTime') {
      // si es necesario, aca escribir la logica de seleccion de cliente

      hideTime();
    } else if (args.item.id === 'showTime') {
      // si es necesario, aca escribir la logica de seleccion de cliente

      showTime();
    } else if (args.item.id === 'Maquina Info') {
      // si es necesario, aca escribir la logica de seleccion de cliente
      setMachineInfoModal(true);
      const nameMachineInfo1 = args.rowData.Maquina;
      setMachineInfoname(nameMachineInfo1);

      setMachineInfoModal(true);
    } else if (args.item.id === 'Margen Prod x Maquina') {
      // si es necesario, aca escribir la logica de seleccion de cliente
      setMachineMarginProd(true);
      const nameMachineInfo2 = args.rowData.Maquina;
      setMachineInfoname2(nameMachineInfo2);

      setMachineMarginModal(true);
    } else if (id.startsWith('hide-')) {
      const columnName = id.substring(5);
      ganttInstance.hideColumn(columnName);
    } else if (id.startsWith('show-')) {
      const columnName = id.substring(5);
      ganttInstance.showColumn(columnName);
    }
  }

  function contextMenuOpen(args) {
    if (args.item && args.item.items) {
      // Update the sub-menu items based on your conditions
      args.items = [...args.item.items];
    }
  }

  //to filter out numbers or strings

  const isNumericValue = (value) =>
    typeof value === 'number' ||
    (!isNaN(Number(value)) && String(value).trim() !== '');

  const getFormattedValue = (value) => {
    if (isNumericValue(value)) {
      return new Intl.NumberFormat('es-GT', {
        style: 'currency',
        currency: 'GTQ',
      }).format(Number(value));
    }
    return value; // This will render any string message as-is
  };

  const getTextColor = (value) => {
    if (isNumericValue(value) && Number(value) < 0) {
      return 'red';
    }
    return 'black';
  };

  return (
    <div>
      <Navbar />

      {loading && (
        <p className='waitTimeExpanding'>
          {' '}
          <Spinner animation='grow' variant='warning' className='spinner' />
          Esto puede tomar un tiempo ......
          <Spinner animation='grow' variant='warning' className='spinner' />
        </p>
      )}

      <div className='leyenda'>
        <div className='madeToOrder'></div>
        <p>Made to Order</p>
        <div className='enMaquina'></div>
        <p>En máquina</p>
        <div className='stockOut'></div>
        <p>Stock Out</p>
        <div className='madeToStock'></div>
        <p>Made to Stock</p>
        <Modal
          show={showInformation}
          onHide={() => setShowInformation(false)}
          backdrop='static'
          keyboard={false}
          size='lg'
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className='container'>
              <div className='d-flex justify-content-between'>
                <div>
                  <h3>Pesos</h3>
                  <p>
                    Stock: {parseInt(weight.stock).toLocaleString('en-US')} kg
                  </p>
                  <p>
                    StockOut:{' '}
                    {parseInt(weight.stockout).toLocaleString('en-US')} kg
                  </p>
                  <p>
                    Orden: {parseInt(weight.orden).toLocaleString('en-US')} kg
                  </p>
                  <p>
                    Máquina: {parseInt(weight.maquina).toLocaleString('en-US')}{' '}
                    kg
                  </p>
                  <p>
                    Total: {parseInt(weight.total).toLocaleString('en-US')} kg
                  </p>
                </div>
                <div>
                  <h3>Información</h3>
                  <p>
                    Horas de molde:{' '}
                    {parseInt(information.mold_hours).toLocaleString('en-US')}{' '}
                    horas
                  </p>
                  <p>
                    Cantidad de OPP:{' '}
                    {information.opp_count.toLocaleString('en-US')}
                  </p>
                  
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <div className='wrapper'>
        <Information show={show} handleClose={handleClose} />

        <Warehouse show={showWarehouse} setShow={setShowWarehouse} />

        <ProductGraphSingle show={showPGS} setShow={setShowPGS} />

        <Calculate
          show={machineRequestModal}
          setShow={setMachineRequestModal}
        />

        <Modal
          show={machineInfoModal}
          onHide={handleClose}
          backdrop='static'
          keyboard={false}
          size='lg'
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <strong> Máquina {machineInfoname} </strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {machineInfo &&
              machineInfo.map((machine, index) => (
                <div className='mainBoxMachineProd' key={index}>
                  <div className='boxMachineProd'>
                    <p>Código de articulo</p>
                    <p>{machine.codigo_de_articulo}</p>
                  </div>
                  <div className='boxMachineProd'>
                    <p>Producto</p>
                    <p>{machine.nombre_del_producto}</p>
                  </div>
                  <div className='boxMachineProd'>
                    <p>Máquina en prod</p>
                    <p>{machine.maquina_en_produccion}</p>
                  </div>
                  <div className='boxMachineProd'>
                    <p>Fecha de inicio</p>
                    <p>{machine.start_time}</p>
                  </div>
                  <div className='boxMachineProd'>
                    <p>Tipo</p>
                    <p>{machine.type}</p>
                  </div>
                </div>
              ))}
          </Modal.Body>
        </Modal>

        <Modal
          show={machineMarginModal}
          onHide={handleClose}
          backdrop='static'
          keyboard={false}
          size='lg'
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <strong> Máquina {machineInfoname2} </strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table border='1' style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>Codigo de articulo</th>
                  <th>itemid</th>
                  <th>Producto</th>
                  <th>Color</th>
                  <th>Tipo</th>
                  <th>Beneficio</th>
                </tr>
              </thead>
              <tbody>
                {machineInfoMarginProd &&
                  machineInfoMarginProd.map((machine, index) => (
                    <tr key={index}>
                      <td>{machine.COD_PROD}</td>
                      <td>{machine.itemid}</td>
                      <td>{machine.product}</td>
                      <td>{machine.color}</td>
                      <td>{machine.type_binary}</td>
                      <td
                        style={{ color: getTextColor(machine.CLASIFICACION) }}
                      >
                        {getFormattedValue(machine.CLASIFICACION)}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </Modal.Body>
        </Modal>

        <GanttComponent
          dataSource={mapped}
          enablePredecessorValidation={false}
          includeWeekend={true}
          treeColumnIndex={1}
          taskFields={taskSettings}
          tooltipSettings={tooltipSettings}
          allowExcelExport={true}
          autoCalculateDateScheduling={true}
          collapseAllParentTasks={true}
          timezone='UTC'
          dayWorkingTime={[{ from: 0, to: 24 }]}
          timelineSettings={{
            timelineViewMode: 'Week',
            topTier: { unit: 'Week' },
            bottomTier: { unit: 'Day', format: 'dd' },
            timelineUnitSize: 35,
          }}
          queryTaskbarInfo={queryTaskbarInfo}
          labelSettings={{
            rightLabel: taskSettings.color_maquina,
            leftLabel: taskSettings.OPP,
            // taskLabel: 'Progress',
          }}
          allowFiltering={true}
          height={'100%'}
          width={'100%'}
          toolbar={[
            { text: 'Expandir', id: 'expandAll', prefixIcon: 'e-expand' },
            { text: 'Ocultar', id: 'colapsedAll', prefixIcon: 'e-collapseall' },
            { text: 'Semanal', id: 'weekPlan', prefixIcon: 'e-week' },
            {
              text: 'Mensual',
              id: 'monthPlan',
              prefixIcon: 'e-month',
              position: 50,
            },
            {
              text: 'Solicitud',
              id: 'machineRequestModal',
              prefixIcon: 'e-plus',
              position: 50,
            },
            {
              text: 'Información',
              id: 'information',
              prefixIcon: 'e-plus',
              position: 50,
            },
            {
              text: 'Cliente',
              id: 'client',
              prefixIcon: 'e-search',
              position: 50,
            },
            {
              text: 'Inventario',
              id: 'inventory',
              prefixIcon: 'e-search',
              position: 50,
            },
            {
              text: 'Grafica Prod',
              id: 'Product',
              prefixIcon: 'e-search',
              position: 50,
            },
            {
              text: 'Limpiar filtro',
              id: 'clearfilter',
              prefixIcon: 'e-trash',
            },
            {
              text: 'Exportar Excel',
              id: 'excelExport',
              prefixIcon: 'e-excelexport',
            },
          ]}
          toolbarClick={onCLickToolBar}
          filterSettings={{
            hierarchyMode: 'Both',
            ignoreAccent: true,
          }}
          enableContextMenu={true}
          contextMenuItems={contextMenuItems}
          contextMenuClick={contextMenuClick}
          // contextMenuOpen={contextMenuOpen}
          ref={(gantt) => (ganttInstance = gantt)}
          //style={ {height: '800px'} }
        >
          <ColumnsDirective>
            <ColumnDirective field='Maquina' width='75' headerText='Máquina' />
            <ColumnDirective field='OPP' width='130' headerText='OPP' />
            <ColumnDirective field='OP' width='130' headerText='OP' />
            <ColumnDirective field='Elem' width='100' headerText='Elem' />

            <ColumnDirective field='Client' width='100' headerText='Cliente' />
            <ColumnDirective field='TaskName' width='120' headerText='Nombre' />
            <ColumnDirective field='itemid' width='120' headerText='itemid' />
            <ColumnDirective
              field='color_maquina'
              width='0'
              headerText='color_maquina'
            />
            <ColumnDirective
              field='StartDate'
              width='100'
              headerText='Inicio'
              template={(fieldData) => {
                const dateObj = new Date(fieldData.StartDate);
                const formattedDate = dateObj.toLocaleDateString('es-ES', {
                  day: '2-digit',
                  month: 'short',
                });
                return <div>{formattedDate}</div>;
              }}
            />
            <ColumnDirective
              field='EndDate'
              width='100'
              headerText='Final'
              template={(fieldData) => {
                const dateObj2 = new Date(fieldData.EndDate);
                const formattedDate2 = dateObj2.toLocaleDateString('es-ES', {
                  day: '2-digit',
                  month: 'short',
                });
                return <div>{formattedDate2}</div>;
              }}
            />
            <ColumnDirective
              field='Fecha_compromiso'
              width='100'
              headerText='Fecha de compromiso'
            />
             <ColumnDirective field="Duration" width="100" headerText='Duración' />
            <ColumnDirective
              field='Machine'
              width='100'
              headerText='Producción'
            />
            <ColumnDirective
              field='Type_machine'
              width='100'
              headerText='Asignación'
            />
            <ColumnDirective field='estilo' width='100' headerText='estilo' />
            <ColumnDirective
              field='configuracion'
              width='100'
              headerText='configuracion'
            />
            <ColumnDirective
              field='DESCRIPCION_COLOR'
              width='100'
              headerText='DESCRIPCION_COLOR'
            />
            <ColumnDirective field='ciclo' width='100' headerText='ciclo' />
            <ColumnDirective field='Time' width='100' headerText='Tiempo' />
          </ColumnsDirective>
          <Inject
            services={[Filter, Toolbar, ContextMenu, Selection, ExcelExport]}
          />
        </GanttComponent>
      </div>
    </div>
  );
};
export default Workload;
