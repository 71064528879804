import React, { useState, useEffect } from 'react';
// import MachineList from './machineList';
import { request } from '../../Data/Requests';
import './machineOnline.css';
import { Navbar } from '../../Navbar/Navbar';
import Modal from 'react-bootstrap/Modal';
import swal from 'sweetalert';
// import { set } from 'date-fns';

//new code start to delete

const MachineOnline = () => {
  const [machineList, setMachineList] = useState([]);
  const [selectedMachines, setSelectedMachines] = useState({});
  const [machinesEdited, setMachinesEdited] = useState([]);
  const [ops, setOps] = useState({});

  const [initialMachineStatuses, setInitialMachineStatuses] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [updating, setUpdating] = useState(false);
  const [preUpdate, setPreUpdate] = useState(true);

  const [cycleErrorMachines, setCycleErrorMachines] = useState([]);
  const [mezclaErrorMachines, setMezclaErrorMachines] = useState([]);
  const [opErrorMachines, setOpErrorMachines] = useState([]);
  const [machineCycles, setMachineCycles] = useState({});

  const handleCycleChange = (maquina, event) => {
    const newCycle = event.target.value;
    setMachineCycles((prevCycles) => ({ ...prevCycles, [maquina]: newCycle }));
  };

  useEffect(() => {
    if (machineList.length === 0) {
      request('https://meg-gt.improgress.net/api/consult_status_machine').then(
        (response) => {
          setMachineList(response);
          response.forEach((machine) => {
            setSelectedMachines((prev) => ({
              ...prev,
              [machine.maquina]: machine.estatus === 1,
            }));
            setOps((prev) => ({
              ...prev,
              [machine.maquina]: machine.op_en_proceso,
            }));
          });
          const initialStatuses = {};
          response.forEach((machine) => {
            initialStatuses[machine.maquina] = machine.estatus === 1;
          });
          setInitialMachineStatuses(initialStatuses);
        }
      );
    }
  }, []);

  const handleToggle = (maquina, estatus) => {
    setSelectedMachines((prev) => ({
      ...prev,
      [maquina]: prev[maquina] !== undefined ? !prev[maquina] : estatus !== 1,
    }));
    setMachinesEdited((prev) => {
      if (prev.includes(maquina)) {
        return prev;
      }
      return [...prev, maquina];
    });
  };

  //this is for cycle check

  const preUpdateFilter = () => {
    const machinesToUpdate = machineList
      .map((machine) => {
        return {
          machine: machine.maquina,
          op: ops[machine.maquina] || machine.op_en_proceso,
          cycle: machineCycles[machine.maquina] || null,
        };
      })
      .filter((obj) => obj.op !== null);

    console.log(JSON.stringify({ orden_maquina: machinesToUpdate }));

    const user = localStorage.getItem('user');
    fetch(
      `https://meg-gt.improgress.net/api/validate_op_in_machine?usuario=${user}`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ orden_maquina: machinesToUpdate }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        let errorMessage = '';

        if (data.detalle) {
          setCycleErrorMachines(data.detalle?.ciclo || []);
          setMezclaErrorMachines(data.detalle?.mezcla || []);
          setOpErrorMachines(data.detalle?.op || []);

          if (data.detalle?.ciclo?.length > 0) {
            errorMessage +=
              'Estas máquinas no tienen un ciclo válido: ' +
              data.detalle.ciclo.join(', ') +
              '<br>';
          }
          if (data.detalle?.mezcla?.length > 0) {
            errorMessage +=
              ' <br> Esta op tiene un código de mezcla: ' +
              data.detalle.mezcla.join(', ') +
              '<br>';
          }
          if (data.detalle?.op?.length > 0) {
            errorMessage +=
              '<br> Estas Op no existen en el sistema: ' +
              data.detalle.op.join(', ') +
              '<br>';
          }

          if (errorMessage) {
            swal({
              title: 'Error',
              content: {
                element: 'span',
                attributes: {
                  innerHTML: errorMessage,
                },
              },
              icon: 'error',
            });
          }
        } else {
          swal('Updates successful', '', 'success');
          setPreUpdate(false);
        }
      });
  };

  // setPreUpdate(false);
  //this is the end

  const handleOpChange = (maquina, event) => {
    const newOp = event.target.value;
    setOps((prevOps) => ({ ...prevOps, [maquina]: newOp }));
    setMachinesEdited((prev) => {
      if (prev.includes(maquina)) {
        return prev;
      }
      return [...prev, maquina];
    });
  };

  const handleSubmit = async () => {
    let error = false;
    const machinesNowOff = machineList
      .filter((machine) => {
        // Determine the current state after toggle
        const currentState =
          selectedMachines[machine.maquina] !== undefined
            ? selectedMachines[machine.maquina]
            : initialMachineStatuses[machine.maquina];
        // Include only machines that are off
        return currentState === false;
      })
      .map((machine) => machine.maquina);

    const user = localStorage.getItem('user');
    machinesEdited.forEach((machine) => {
      if (selectedMachines[machine] && ops[machine].length === 0) {
        error = true;
      }
    });
    const payload = {
      usuario: user,
      maquina: machinesNowOff.join(','), // Send the list of machines now off
    };
    if (!error) {
      fetch('https://meg-gt.improgress.net/api/update_machine', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then((data) => {
          handleMachineUpdateResponse(data);
        });
    } else {
      //alert("No se puede dejar una maquina activa sin op")\
      await swal('No se puede dejar una maquina activa sin op', '', 'error');
    }
  };

  const searchMachine = (toSearch) => {
    // search a machine into the object machineList and return the values
    for (let i = 0; i < machineList.length; i++) {
      if (machineList[i].maquina === toSearch) {
        return machineList[i].op_en_proceso;
      }
    }
  };

  const updateMachinesOp = () => {
    const machinesToUpdate = machineList
      .map((machine) => {
        if (ops[machine.maquina]) {
          return {
            machine: machine.maquina,
            op: ops[machine.maquina],
          };
        } else {
          return {
            machine: machine.maquina,
            op: machine.op_en_proceso,
          };
        }
      })
      .filter((obj) => obj.op !== null);
    // console.log(JSON.stringify({ orden_maquina: machinesToUpdate }));
    const user = localStorage.getItem('user');
    fetch(
      `https://meg-gt.improgress.net/api/update_op_in_machine?usuario=${user}`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ orden_maquina: machinesToUpdate }),
      }
    )
      .then((response) => response.json())
      .then(async (data) => {
        const pepito = `${data.message} status es ${data.status}`;
        setModalMessage('Por favor esperar a que esta ventana se cierre');
        // setUpdating(false);
        // console.log(data);
        if (data.status.includes('ok')) {
          let win = window.open(
            'https://meg-gt.improgress.net/req/update',
            '_blank'
          );

          let pollTimer = window.setInterval(function () {
            if (win.document.body.textContent === 'null') {
              win.close();
              window.clearInterval(pollTimer);
              setShowModal(false);

              setUpdating(false);
            }
          }, 700);
        }
        /*setModalMessage(pepito);
      setUpdating(false);*/
        setShowModal(false);
        await swal(pepito, '', 'success');
        showModal(false);
      });
  };

  // };

  const handleMachineUpdateResponse = async (data) => {
    if (Array.isArray(data) && data.includes('updated_machine')) {
      await swal(
        'Proseguir con esta acción toma de 10 a 15 minutos, por favor no refrescar la página',
        '',
        'info'
      ).then(updateMachinesOp);
      setShowModal(true);
      // setModalMessage("Proseguir con esta acción toma de 5 a 8 minutos, por favor no refrescar la página");
      setUpdating(true);
    } else {
      alert(data);
    }
  };

  return (
    <div>
      <Navbar />
      <div className='generalMachineList'>
        <div>
          <h3 style={{ textAlign: 'center' }}>Central</h3>
          <div className='topSanJose2'>
            {machineList
              .filter((machine) => !machine.maquina.startsWith('Z'))
              .sort((a, b) => a.maquina.localeCompare(b.maquina))
              .map((machine) => (
                <div className='testingdivs' key={machine.maquina}>
                  <label className='machineNameList'>{machine.maquina}</label>
                  <div className='toggle-switch'>
                    <input
                      className='checkbox'
                      type='checkbox'
                      name={machine.maquina}
                      id={machine.maquina}
                      checked={
                        selectedMachines[machine.maquina] === true ||
                        (selectedMachines[machine.maquina] === undefined &&
                          machine.estatus === 1)
                      }
                      onChange={() =>
                        handleToggle(machine.maquina, machine.estatus)
                      }
                    />
                    <label className='label' htmlFor={machine.maquina}>
                      <span className='inner' />
                      <span className='switch' />
                    </label>
                  </div>
                  <input
                    type='text'
                    className='opInput'
                    value={
                      ops[machine.maquina] !== undefined
                        ? ops[machine.maquina]
                        : machine.op_en_proceso
                    }
                    onChange={(event) => handleOpChange(machine.maquina, event)}
                    disabled={
                      selectedMachines[machine.maquina] === false ||
                      (selectedMachines[machine.maquina] === undefined &&
                        machine.estatus === 0)
                    }
                  />
                  {cycleErrorMachines.includes(machine.maquina) && (
                    <input
                      type='text'
                      placeholder='nuevo ciclo'
                      onChange={(event) =>
                        handleCycleChange(machine.maquina, event)
                      }
                      className='cycleInput'
                    />
                  )}
                </div>
              ))}
          </div>
        </div>

        <div>
          <h3 style={{ textAlign: 'center' }}>San Jose</h3>
          <div className='topSanJose2'>
            {machineList
              .filter((machine) => machine.maquina.startsWith('Z'))
              .sort((a, b) => a.maquina.localeCompare(b.maquina))
              .map((machine) => (
                <div className='testingdivs' key={machine.maquina}>
                  <label className='machineNameList'>{machine.maquina}</label>
                  <div className='toggle-switch'>
                    <input
                      className='checkbox'
                      type='checkbox'
                      name={machine.maquina}
                      id={machine.maquina}
                      checked={
                        selectedMachines[machine.maquina] === true ||
                        (selectedMachines[machine.maquina] === undefined &&
                          machine.estatus === 1)
                      }
                      onChange={() =>
                        handleToggle(machine.maquina, machine.estatus)
                      }
                    />
                    <label className='label' htmlFor={machine.maquina}>
                      <span className='inner' />
                      <span className='switch' />
                    </label>
                  </div>
                  <input
                    type='text'
                    className='opInput'
                    value={
                      ops[machine.maquina] !== undefined
                        ? ops[machine.maquina]
                        : machine.op_en_proceso
                    }
                    onChange={(event) => handleOpChange(machine.maquina, event)}
                    disabled={
                      selectedMachines[machine.maquina] === false ||
                      (selectedMachines[machine.maquina] === undefined &&
                        machine.estatus === 0)
                    }
                  />
                  {cycleErrorMachines.includes(machine.maquina) && (
                    <input
                      type='text'
                      placeholder='nuevo ciclo'
                      onChange={(event) =>
                        handleCycleChange(machine.maquina, event)
                      }
                      className='cycleInput'
                    />
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className='btnContainer'>
        {preUpdate ? (
          <button className='btn btn-primary' onClick={preUpdateFilter}>
            Verificar
          </button>
        ) : (
          <button className='btn btn-primary' onClick={handleSubmit}>
            Actualizar
          </button>
        )}
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Estado de Actualización</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Por favor esperar a que esta ventana cierre</p>
          <p>Puede tomar hasta 15 minutos, no refrescar la página</p>
        </Modal.Body>
        <Modal.Footer>
          {updating ? (
            <button
              className='w-100 btn btn-primary'
              onClick={updateMachinesOp}
              style={{ display: 'flex', gap: '25px', alignItems: 'center' }}
            >
              Calculando el plan
              <div
                className='spinner-border ms-auto text-primary'
                role='status'
              >
                <span className='visually-hidden'>...</span>
              </div>
            </button>
          ) : (
            <div className='spinner-border text-primary' role='status'>
              <button
                className='btn btn-secondary'
                onClick={() => setShowModal(false)}
              >
                Cerrar
              </button>
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MachineOnline;
