import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
// import Authentication from "../../Authentication/Authentication";
import AuthenticateUser from "../../authentication/AuthenticateUser";
import { requestpost } from "../Utils/Requests";
import { parseDate2 } from "../Utils/Utils";

const LogInForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
    const isAuth = AuthenticateUser({ username, password });
    if (isAuth) {
      window.localStorage.setItem("user", username);
      requestpost("https://bck.improgress.net/api/insert/logs", {
        user: username,
        date: parseDate2(new Date()).toISOString().split("T")[0],
        project: "MEG PRD",
      }).then((res) => {
        console.log(res);
      });
      navigate("/home");
    } else {
      setError(true);
    }
  };

  return (
    <div>
      <form className="d-flex justify-content-center align-items-between">
        <div className="col-11">
          {/* error message */}
          <div
            className={`alert alert-danger align-items-center ${
              error ? "d-flex" : "d-none"
            }`}
            role="alert"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
              viewBox="0 0 16 16"
              role="img"
              aria-label="Warning:"
            >
              <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
            </svg>
            <div>Usuario o contraseña incorrectos.</div>
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="username"
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Nombre de usuario"
            />
            <label htmlFor="username" className="form-label">
              Usuario
            </label>
          </div>
          <div className="form-floating mb-4">
            <input
              type="password"
              className="form-control"
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Constraseña"
            />
            <label htmlFor="password" className="form-label">
              Constraseña
            </label>
          </div>
          <button
            className="w-100 btn btn-primary py-2 justify-self-end"
            onClick={handleClick}
          >
            Iniciar Sesión
          </button>
        </div>
      </form>
    </div>
  );
};

export default LogInForm;