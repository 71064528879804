import React from 'react'
import './navbar.scss'
import { useNavigate} from "react-router-dom";


export const Navbar = (props) => {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('user');
        navigate('/');
    }

    const handleGant = (e) => {
        navigate('/gantt')
    }

    const handleGraphs = (e) => {
        if (e.target.id === 'sales')
            navigate('/graphs')
        else
            navigate('/margin')
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark p-0 pt-1">
            <div className="container-fluid">
                <a className="navbar-brand" href="/home">
                    <img src={'./images/Mega.png'} alt="Mega logo" width="90px" className='my-1'/>
                </a>
                <div>
                    <button className="link fw-semibold text-light cool-link me-3" type="submit" onClick={handleGant}
                            id='sales'>
                        PLAN
                    </button>
                    <button className="link fw-semibold text-light cool-link me-3" type="submit" onClick={handleGraphs}
                            id='sales'>
                        VENTAS
                    </button>

                    <button className="link fw-semibold text-light cool-link me-3" type="submit" onClick={handleGraphs}>
                        MARGEN
                    </button>

                    <button className="link fw-semibold text-light cool-link" type="submit" onClick={handleLogout}>
                        CERRAR SESIÓN
                    </button>

                </div>
            </div>
        </nav>
    )
}
// export default Navbar;