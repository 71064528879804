import React, { useEffect, useState } from 'react';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Bar,
  BarChart,
  ReferenceLine,
  ResponsiveContainer,
  ComposedChart,
  Scatter,
  Cell,
  LabelList,
} from 'recharts';
import { Navbar } from '../Navbar/Navbar';
import { requestpost } from '../Data/Requests';
import './margin.css';
import linea_base2023 from './linea_base2023.json'
import linea_basep2023 from './linea_basep2023.json'

const Margin = () => {
  const [marginData, setMarginData] = useState([]);
  const [marginData_p, setMarginData_p] = useState([]);
  const [predictionMonth, setPredictionMonth] = useState('');

  useEffect(() => {
    requestpost(`https://meg-gt.improgress.net/api/consult_linea_base`).then(
      (response) => {
        // TODO DELETE
        console.log("json100000", JSON.stringify(response));
        setMarginData(linea_base2023.concat(response[0]));
        // setMarginData(response[0]);
        setPredictionMonth(response[0][response[0].length - 1].name);
        console.log("json1", JSON.stringify(response[0]));
        console.log("joson2", JSON.stringify(response[0][response[0].length - 1].name));
      }
    );
  }, []);

  useEffect(() => {
    requestpost(`https://meg-gt.improgress.net/api/consult_linea_base_p`).then(
      (response) => {
        // TODO DELETE
        console.log("json30000000", JSON.stringify(response[0]));
        let linea_baseplus = (linea_basep2023.concat(response[0]));
        setMarginData_p(linea_baseplus);
        // setMarginData_p(linea_basep2023);
        // setMarginData_p(response[0]);
        console.log("json3", JSON.stringify(response[0]));
        console.log("json30B", linea_baseplus);
      }
    );
  }, []);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className='shadow rounded bg-light px-2 py-1'>
          <p className='m-0 mb-1 p-0 fw-semibold border-bottom'>{label}</p>
          <p className='m-0 p-0' style={{ color: payload[0].color }}>
            {payload[0].name}: {payload[0].value}
          </p>
        </div>
      );
    }
  };

  const CustomTooltipTotal = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className='shadow rounded bg-light px-2 py-1'>
          <p className='m-0 mb-1 p-0 fw-semibold border-bottom'>{label}</p>
          <p className='m-0 p-0' style={{ color: payload[0].color }}>
            {payload[0].name}: {payload[0].value}
          </p>
        </div>
      );
    }
  };

  const renderCustomLabel = (props) => {
    const { x, y, value } = props;
    const formattedValue = value.toFixed(2); // Format as percentage with 2 decimals
    if (props.index === marginData_p.length - 1) {
      return (
        <g>
          <rect x={x + 40} y={y - 23} width={40} height={16} fill='white' />
          <text
            x={x + 60}
            y={y}
            dy={-10}
            fill='#333'
            fontSize={12}
            textAnchor='middle'
          >
            {formattedValue}
          </text>
        </g>
      );
    }

    return null;
  };

  const bodyTable = marginData.map((entry, index) => (
    <tr key={index}>
      <th className={`text-center vertical-divider`} scope='row'>
        {entry.name}
      </th>
      <td className='text-center'>{entry['cp.22'].toFixed(2)}</td>
      <td className='text-center'>{entry['cp.23'].toFixed(2)}</td>
      <td
        className={`text-center vertical-divider`}
        style={{
          color: entry['cp.d'] < 0 ? 'red' : 'green',
          fontWeight: 'bold',
        }}
      >
        {((entry['cp.d'] * 100) / 100).toFixed(2)}
      </td>
    </tr>
  ));

  const bodyTable1 = marginData.map((entry, index) => (
    <tr key={index}>
      <th className={`text-center vertical-divider`} scope='row'>
        {entry.name}
      </th>
      <td className='text-center'>{entry['ci.22'].toFixed(2)}</td>
      <td className='text-center'>{entry['ci.23'].toFixed(2)}</td>
      <td
        className={`text-center vertical-divider`}
        style={{
          color: entry['ci.d'] < 0 ? 'red' : 'green',
          fontWeight: 'bold',
        }}
      >
        {((entry['ci.d'] * 100) / 100).toFixed(2)}
      </td>
    </tr>
  ));

  const bodyTable2 = marginData.map((entry, index) => (
    <tr key={index}>
      <th className={`text-center vertical-divider`} scope='row'>
        {entry.name}
      </th>
      <td className='text-center'>{parseFloat(entry['ms.22']).toFixed(2)}</td>
      <td className='text-center'>{parseFloat(entry['ms.23']).toFixed(2)}</td>
      <td
        className={`text-center vertical-divider`}
        style={{
          color: entry['ms.d'] < 0 ? 'red' : 'green',
          fontWeight: 'bold',
        }}
      >
        {(parseFloat(entry['ms.d'] * 100) / 100).toFixed(2)}
      </td>
    </tr>
  ));

  const bodyTable3 = marginData.map((entry, index) => (
    <tr key={index}>
      <th className={`text-center vertical-divider`} scope='row'>
        {entry.name}
      </th>
      <td className='text-center'> {parseFloat(entry['mv.22']).toFixed(2)}</td>
      <td className='text-center'> {parseFloat(entry['mv.23']).toFixed(2)}</td>
      <td
        className={`text-center vertical-divider`}
        style={{
          color: entry['mv.d'] < 0 ? 'red' : 'green',
          fontWeight: 'bold',
        }}
      >
        {(parseFloat(entry['mv.d'] * 100) / 100).toFixed(2)}
      </td>
    </tr>
  ));

  const bodyTable4 = marginData.map((entry, index) => (
    <tr key={index}>
      <th className={`text-center vertical-divider`} scope='row'>
        {entry.name}
      </th>
      <td
        className='text-center'
        style={{
          color: entry['total'] < 0 ? 'red' : 'green',
          fontWeight: 'bold',
        }}
      >
        {((entry['total'] * 100) / 100).toFixed(2)}
      </td>
    </tr>
  ));

  const bodyTable_p = marginData_p.map((entry, index) => (
    <tr key={index}>
      <th className={`text-center vertical-divider`} scope='row'>
        {entry.name}
      </th>
      <td className='text-center'>{entry['cp.22'].toFixed(2)}</td>
      <td className='text-center'>{entry['cp.23'].toFixed(2)}</td>
      <td
        className={`text-center vertical-divider`}
        style={{
          color: entry['cp.d'] < 0 ? 'red' : 'green',
          fontWeight: 'bold',
        }}
      >
        {((entry['cp.d'] * 100) / 100).toFixed(2)}
      </td>
    </tr>
  ));

  const bodyTable1_p = marginData_p.map((entry, index) => (
    <tr key={index}>
      <th className={`text-center vertical-divider`} scope='row'>
        {entry.name}
      </th>
      <td className='text-center'>{entry['ci.22'].toFixed(2)}</td>
      <td className='text-center'>{entry['ci.23'].toFixed(2)}</td>
      <td
        className={`text-center vertical-divider`}
        style={{
          color: entry['ci.d'] < 0 ? 'red' : 'green',
          fontWeight: 'bold',
        }}
      >
        {((entry['ci.d'] * 100) / 100).toFixed(2)}
      </td>
    </tr>
  ));

  const bodyTable2_p = marginData_p.map((entry, index) => (
    <tr key={index}>
      <th className={`text-center vertical-divider`} scope='row'>
        {entry.name}
      </th>
      <td className='text-center'>{parseFloat(entry['ms.22']).toFixed(2)}</td>
      <td className='text-center'>{parseFloat(entry['ms.23']).toFixed(2)}</td>
      <td
        className={`text-center vertical-divider`}
        style={{
          color: entry['ms.d'] < 0 ? 'red' : 'green',
          fontWeight: 'bold',
        }}
      >
        {(parseFloat(entry['ms.d'] * 100) / 100).toFixed(2)}
      </td>
    </tr>
  ));

  const bodyTable3_p = marginData_p.map((entry, index) => (
    <tr key={index}>
      <th className={`text-center vertical-divider`} scope='row'>
        {entry.name}
      </th>
      <td className='text-center'> {parseFloat(entry['mv.22']).toFixed(2)}</td>
      <td className='text-center'> {parseFloat(entry['mv.23']).toFixed(2)}</td>
      <td
        className={`text-center vertical-divider`}
        style={{
          color: entry['mv.d'] < 0 ? 'red' : 'green',
          fontWeight: 'bold',
        }}
      >
        {(parseFloat(entry['mv.d'] * 100) / 100).toFixed(2)}
      </td>
    </tr>
  ));

  const bodyTable4_p = marginData_p.map((entry, index) => (
    <tr key={index}>
      <th className={`text-center vertical-divider`} scope='row'>
        {entry.name}
      </th>
      <td
        className='text-center'
        style={{
          color: entry['total'] < 0 ? 'red' : 'green',
          fontWeight: 'bold',
        }}
      >
        {((entry['total'] * 100) / 100).toFixed(2)}
      </td>
    </tr>
  ));

  const DataFormatter = (value) => {
    return value.toFixed(1);
  };

  return (
    <>
      <Navbar />
      <h2 className='text-center m-4 fw-medium'>
        Indicadores de eficiencia de Megaplast 2022 vs 2023 en millones de
        Quetzales
      </h2>
      <div className='m-0 m-md-4 row row-cols-1 row-cols-md-2'>
        {marginData.length === 0 && marginData_p.length === 0 ? (
          <div className='spinner-border text-primary' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        ) : (
          <>
            <div className='divide_chart'>
              <h6>
                Resultado utilizando la producción reportada en Excel del WIP
              </h6>
              <ResponsiveContainer width='100%' height='28%'>
                <ComposedChart
                  data={marginData}
                  syncId='anyId'
                  key={Math.random()}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                  stackOffset='sign'
                >
                  <CartesianGrid horizontal={false} vertical={false} />
                  <XAxis dataKey='name' padding={{ left: 30, right: 30 }} tick={{ fontSize: 10 }}/>
                  <YAxis tickFormatter={(value) => DataFormatter(value)} />
                  <ReferenceLine y={0} stroke='#000' />
                  <Tooltip content={CustomTooltipTotal} />
                  <Scatter
                    name='Margen de Stockout 2022'
                    dataKey='ci-22'
                    fill='#17375e'
                  />
                  <Scatter
                    name='Margen de Stockout 2023'
                    dataKey='ci-23'
                    fill='#FC4C02'
                  />
                  <Bar dataKey='total' name='Diferencia' cornerRadius={55}>
                    {marginData.map((entry) => (
                      <Cell
                        fill={entry['total'] >= 0 ? '#17375e' : '#b20e0d'}
                      />
                    ))}
                    <LabelList dataKey='total' content={renderCustomLabel} />
                  </Bar>
                </ComposedChart>
              </ResponsiveContainer>
              <div className='tables s-3 s-md-4'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th className='text-center' scope='col' rowSpan='2'>
                        Mes
                      </th>
                      <th className='text-center' scope='col' rowSpan='2'>
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody className='table-group-divider'>{bodyTable4}</tbody>
                </table>
                <h6>En millones de Quetzales</h6>
              </div>
            </div>
            <div className='divide_chart'>
            <h6>
                Resultado utilizando la producción en la BD de Pallets 365
            </h6>
              <ResponsiveContainer width='100%' height='28%'>
                <ComposedChart
                  data={marginData_p}
                  key={Math.random()}
                  syncId='anyId'
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                  stackOffset='sign'
                >
                  <CartesianGrid horizontal={false} vertical={false} />
                  <XAxis dataKey='name' padding={{ left: 30, right: 30 }} tick={{ fontSize: 10 }} />
                  <YAxis tickFormatter={(value) => DataFormatter(value)} />
                  <ReferenceLine y={0} stroke='#000' />
                  <Tooltip content={CustomTooltipTotal} />
                  <Scatter
                    name='Margen de Stockout 2022'
                    dataKey='ci-22'
                    fill='#17375e'
                  />
                  <Scatter
                    name='Margen de Stockout 2023'
                    dataKey='ci-23'
                    fill='#FC4C02'
                  />
                  <Bar dataKey='total' name='Diferencia'>
                    {marginData_p.map((entry) => (
                      <Cell
                        fill={entry['total'] >= 0 ? '#17375e' : '#b20e0d'}
                      />
                    ))}
                    <LabelList dataKey='total' content={renderCustomLabel} />
                  </Bar>
                </ComposedChart>
              </ResponsiveContainer>
              <div className='tables s-3 s-md-4'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th className='text-center' scope='col' rowSpan='2'>
                        Mes
                      </th>
                      <th className='text-center' scope='col' rowSpan='2'>
                        Total
                      </th>
                    </tr>
                    <tr></tr>
                  </thead>
                  <tbody className='table-group-divider'>{bodyTable4_p}</tbody>
                </table>
                <h6>En millones de Quetzales</h6>
              </div>
            </div>
            <div className='divide_chart'>
            <h6>Costo de lo Producido Excel</h6>
              <ResponsiveContainer width='100%' height='28%'>
                <BarChart
                  data={marginData}
                  key={Math.random()}
                  syncId='anyId'
                  margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
                  stackOffset='sign'
                >
                  <CartesianGrid horizontal={false} vertical={false} />
                  <XAxis dataKey='name' tick={{ fontSize: 10 }} />
                  <YAxis tickFormatter={(value) => DataFormatter(value)} />
                  <ReferenceLine y={0} stroke='#000' />
                  <Tooltip content={CustomTooltip} />
                  <Bar dataKey='cp.d' name='Diferencia'>
                    {marginData.map((entry) => (
                      <Cell fill={entry['cp.d'] >= 0 ? '#17375e' : '#b20e0d'} />
                    ))}
                    <LabelList dataKey='cp.d' content={renderCustomLabel} />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
              <div className='tables m-3 m-md-4'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th className='text-center' scope='col' rowSpan='2'>
                        Mes
                      </th>
                      <th className='text-center' scope='col' colSpan='3'>
                        Costo Producción
                      </th>
                    </tr>
                    <tr>
                      <th className='text-center' scope='col'>
                        2022
                      </th>
                      <th className='text-center' scope='col'>
                        2023
                      </th>
                      <th className='text-center' scope='col'>
                        Diferencia
                      </th>
                    </tr>
                  </thead>
                  <tbody className='table-group-divider'>{bodyTable}</tbody>
                </table>
                <div className='container d-flex'>
                  <p className='m-auto'>*Pronóstico de {predictionMonth}*</p>
                  <h6 className='m-auto'>En millones de Quetzales</h6>
                </div>
              </div>
            </div>
            <div className='divide_chart'>
            <h6>Costo de lo Producido Pallets</h6>
              <ResponsiveContainer width='100%' height='28%'>
                <BarChart
                  data={marginData_p}
                  key={Math.random()}
                  syncId='anyId'
                  margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
                  stackOffset='sign'
                >
                  <CartesianGrid horizontal={false} vertical={false} />
                  <XAxis dataKey='name' tick={{ fontSize: 10 }} />
                  <YAxis tickFormatter={(value) => DataFormatter(value)} />
                  <ReferenceLine y={0} stroke='#000' />
                  <Tooltip content={CustomTooltip} />
                  <Bar dataKey='cp.d' name='Diferencia'>
                    {marginData_p.map((entry) => (
                      <Cell fill={entry['cp.d'] >= 0 ? '#17375e' : '#b20e0d'} />
                    ))}
                    <LabelList dataKey='cp.d' content={renderCustomLabel} />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
              <div className='tables m-3 m-md-4'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th className='text-center' scope='col' rowSpan='2'>
                        Mes
                      </th>
                      <th className='text-center' scope='col' colSpan='3'>
                        Costo Producción
                      </th>
                    </tr>
                    <tr>
                      <th className='text-center' scope='col'>
                        2022
                      </th>
                      <th className='text-center' scope='col'>
                        2023
                      </th>
                      <th className='text-center' scope='col'>
                        Diferencia
                      </th>
                    </tr>
                  </thead>
                  <tbody className='table-group-divider'>{bodyTable_p}</tbody>
                </table>
                <div className='container d-flex'>
                  <p className='m-auto'>*Pronóstico de {predictionMonth}*</p>
                  <h6 className='m-auto'>En millones de Quetzales</h6>
                </div>
              </div>
            </div>
            <div className='divide_chart'>
            <h6>Costo del Inventario Excel</h6>
              <ResponsiveContainer width='100%' height='28%'>
                <BarChart
                  data={marginData}
                  key={Math.random()}
                  syncId='anyId'
                  margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
                  stackOffset='sign'
                >
                  <CartesianGrid horizontal={false} vertical={false} />
                  <XAxis dataKey='name' padding={{ left: 30, right: 30 }} tick={{ fontSize: 10 }} />
                  <YAxis tickFormatter={(value) => DataFormatter(value)} />
                  <ReferenceLine y={0} stroke='#000' />
                  <Tooltip content={CustomTooltip} />
                  <Bar dataKey='ci.d' name='Diferencia'>
                    {marginData.map((entry) => (
                      <Cell fill={entry['ci.d'] >= 0 ? '#17375e' : '#b20e0d'} />
                    ))}
                    <LabelList dataKey='ci.d' content={renderCustomLabel} />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
              <div className='tables m-3 m-md-4'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th className='text-center' scope='col' rowSpan='2'>
                        Mes
                      </th>
                      <th className='text-center' scope='col' colSpan='3'>
                        Costo del Inventario
                      </th>
                    </tr>
                    <tr>
                      <th className='text-center' scope='col'>
                        2022
                      </th>
                      <th className='text-center' scope='col'>
                        2023
                      </th>
                      <th className='text-center' scope='col'>
                        Diferencia
                      </th>
                    </tr>
                  </thead>
                  <tbody className='table-group-divider'>{bodyTable1}</tbody>
                </table>
                <div className='container d-flex'>
                  <p className='m-auto'>*Pronóstico de {predictionMonth}*</p>
                  <h6 className='m-auto'>En millones de Quetzales</h6>
                </div>
              </div>
            </div>
            <div className='divide_chart'>
            <h6>Costo del Inventario Pallets</h6>
              <ResponsiveContainer width='100%' height='28%'>
                <BarChart
                  data={marginData_p}
                  key={Math.random()}
                  syncId='anyId'
                  margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
                  stackOffset='sign'
                >
                  <CartesianGrid horizontal={false} vertical={false} />
                  <XAxis dataKey='name' padding={{ left: 30, right: 30 }} tick={{ fontSize: 10 }} />
                  <YAxis tickFormatter={(value) => DataFormatter(value)} />
                  <ReferenceLine y={0} stroke='#000' />
                  <Tooltip content={CustomTooltip} />
                  <Bar dataKey='ci.d' name='Diferencia'>
                    {marginData_p.map((entry) => (
                      <Cell fill={entry['ci.d'] >= 0 ? '#17375e' : '#b20e0d'} />
                    ))}
                    <LabelList dataKey='ci.d' content={renderCustomLabel} />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
              <div className='tables m-3 m-md-4'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th className='text-center' scope='col' rowSpan='2'>
                        Mes
                      </th>
                      <th className='text-center' scope='col' colSpan='3'>
                        Costo del Inventario
                      </th>
                    </tr>
                    <tr>
                      <th className='text-center' scope='col'>
                        2022
                      </th>
                      <th className='text-center' scope='col'>
                        2023
                      </th>
                      <th className='text-center' scope='col'>
                        Diferencia
                      </th>
                    </tr>
                  </thead>
                  <tbody className='table-group-divider'>{bodyTable1_p}</tbody>
                </table>
                <div className='container d-flex'>
                  <p className='m-auto'>*Pronóstico de {predictionMonth}*</p>
                  <h6 className='m-auto'>En millones de Quetzales</h6>
                </div>
              </div>
            </div>
            <div className='divide_chart'>
            <h6>Margen del Stockout Excel</h6>
              <ResponsiveContainer width='100%' height='28%'>
                <BarChart
                  data={marginData}
                  key={Math.random()}
                  syncId='anyId'
                  margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
                  stackOffset='sign'
                >
                  <CartesianGrid horizontal={false} vertical={false} />
                  <XAxis dataKey='name' padding={{ left: 30, right: 30 }} tick={{ fontSize: 10 }} />
                  <YAxis tickFormatter={(value) => DataFormatter(value)} />
                  <ReferenceLine y={0} stroke='#000' />
                  <Tooltip content={CustomTooltip} />
                  <Bar dataKey='ms.d' name='Diferencia'>
                    {marginData.map((entry) => (
                      <Cell fill={entry['ms.d'] >= 0 ? '#17375e' : '#b21715'} />
                    ))}
                    <LabelList dataKey='ms.d' content={renderCustomLabel} />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
              <div className='tables m-3 m-md-4'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th className='text-center' scope='col' rowSpan='2'>
                        Mes
                      </th>
                      <th className='text-center' scope='col' colSpan='3'>
                        Margen del Stockout
                      </th>
                    </tr>
                    <tr>
                      <th className='text-center' scope='col'>
                        2022
                      </th>
                      <th className='text-center' scope='col'>
                        2023
                      </th>
                      <th className='text-center' scope='col'>
                        Diferencia
                      </th>
                    </tr>
                  </thead>
                  <tbody className='table-group-divider'>{bodyTable2}</tbody>
                </table>
                <div className='container d-flex'>
                  <p className='m-auto'>*Pronóstico de {predictionMonth}*</p>
                  <h6 className='m-auto'>En millones de Quetzales</h6>
                </div>
              </div>
            </div>
            <div className='divide_chart'>
            <h6>Margen del Stockout Pallets</h6>
              <ResponsiveContainer width='100%' height='28%'>
                <BarChart
                  data={marginData_p}
                  key={Math.random()}
                  syncId='anyId'
                  margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
                  stackOffset='sign'
                >
                  <CartesianGrid horizontal={false} vertical={false} />
                  <XAxis dataKey='name' padding={{ left: 30, right: 30 }} tick={{ fontSize: 10 }} />
                  <YAxis tickFormatter={(value) => DataFormatter(value)} />
                  <ReferenceLine y={0} stroke='#000' />
                  <Tooltip content={CustomTooltip} />
                  <Bar dataKey='ms.d' name='Diferencia'>
                    {marginData_p.map((entry) => (
                      <Cell fill={entry['ms.d'] >= 0 ? '#17375e' : '#b21715'} />
                    ))}
                    <LabelList dataKey='ms.d' content={renderCustomLabel} />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
              <div className='tables m-3 m-md-4'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th className='text-center' scope='col' rowSpan='2'>
                        Mes
                      </th>
                      <th className='text-center' scope='col' colSpan='3'>
                        Margen del Stockout
                      </th>
                    </tr>
                    <tr>
                      <th className='text-center' scope='col'>
                        2022
                      </th>
                      <th className='text-center' scope='col'>
                        2023
                      </th>
                      <th className='text-center' scope='col'>
                        Diferencia
                      </th>
                    </tr>
                  </thead>
                  <tbody className='table-group-divider'>{bodyTable2_p}</tbody>
                </table>
                <div className='container d-flex'>
                  <p className='m-auto'>*Pronóstico de {predictionMonth}*</p>
                  <h6 className='m-auto'>En millones de Quetzales</h6>
                </div>
              </div>
            </div>
            <div className='divide_chart'>
            <h6>Margen de lo Vendido Excel</h6>
              <ResponsiveContainer width='100%' height='28%'>
                <BarChart
                  data={marginData}
                  key={Math.random()}
                  syncId='anyId'
                  margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
                  stackOffset='sign'
                >
                  <CartesianGrid horizontal={false} vertical={false} />
                  <XAxis dataKey='name' padding={{ left: 30, right: 30 }} tick={{ fontSize: 10 }} />
                  <YAxis tickFormatter={(value) => DataFormatter(value)} />
                  <ReferenceLine y={0} stroke='#000' />
                  <Tooltip content={CustomTooltip} />
                  <Bar dataKey='mv.d' name='Diferencia'>
                    {marginData.map((entry) => (
                      <Cell fill={entry['mv.d'] > 0 ? '#17375e' : '#b51816'} />
                    ))}
                    <LabelList dataKey='mv.d' content={renderCustomLabel} />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
              <div className='tables m-3 m-md-4'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th className='text-center' scope='col' rowSpan='2'>
                        Mes
                      </th>
                      <th className='text-center' scope='col' colSpan='3'>
                        Margen de lo Vendido
                      </th>
                    </tr>
                    <tr>
                      <th className='text-center' scope='col'>
                        2022
                      </th>
                      <th className='text-center' scope='col'>
                        2023
                      </th>
                      <th className='text-center' scope='col'>
                        Diferencia
                      </th>
                    </tr>
                  </thead>
                  <tbody className='table-group-divider'>{bodyTable3}</tbody>
                </table>
                <div className='container d-flex'>
                  <p className='m-auto'>*Pronóstico de {predictionMonth}*</p>
                  <h6 className='m-auto'>En millones de Quetzales</h6>
                </div>
              </div>
            </div>
            <div className='divide_chart'>
            <h6>Margen de lo Vendido Pallets</h6>
              <ResponsiveContainer width='100%' height='28%'>
                <BarChart
                  data={marginData_p}
                  syncId='anyId'
                  key={Math.random()}
                  margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
                  stackOffset='sign'
                >
                  <CartesianGrid horizontal={false} vertical={false} />
                  <XAxis dataKey='name' padding={{ left: 30, right: 30 }} tick={{ fontSize: 10 }} />
                  <YAxis tickFormatter={(value) => DataFormatter(value)} />
                  <ReferenceLine y={0} stroke='#000' />
                  <Tooltip content={CustomTooltip} />
                  <Bar dataKey='mv.d' name='Diferencia'>
                    {marginData_p.map((entry) => (
                      <Cell fill={entry['mv.d'] > 0 ? '#17375e' : '#b51816'} />
                    ))}
                    <LabelList dataKey='mv.d' content={renderCustomLabel} />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
              <div className='tables m-3 m-md-4'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th className='text-center' scope='col' rowSpan='2'>
                        Mes
                      </th>
                      <th className='text-center' scope='col' colSpan='3'>
                        Margen de lo Vendido
                      </th>
                    </tr>
                    <tr>
                      <th className='text-center' scope='col'>
                        2022
                      </th>
                      <th className='text-center' scope='col'>
                        2023
                      </th>
                      <th className='text-center' scope='col'>
                        Diferencia
                      </th>
                    </tr>
                  </thead>
                  <tbody className='table-group-divider'>{bodyTable3_p}</tbody>
                </table>
                <div className='container d-flex'>
                  <p className='m-auto'>*Pronóstico de {predictionMonth}*</p>
                  <h6 className='m-auto'>En millones de Quetzales</h6>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Margin;
