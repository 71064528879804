import React, {useState, useEffect} from "react";
import AWS from "aws-sdk";
import catalogData from "./catalogo2.json";
import {Link} from "react-router-dom";
import "./megaSales.scss";
import catalogId from "./catalogoId.json";
import stylesData from "./styles.json";
import configurationData from "./configuration.json";
import clientsData from "./clients.json";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import {set} from "date-fns";
import {da} from "date-fns/locale";
import {options} from "../Utils/Utils";

// Set up AWS SES configuration
AWS.config.update({
    accessKeyId: process.env.AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY,
    region: process.env.AWS_REGION,
});

const ses = new AWS.SES({apiVersion: "2010-12-01"});

const MegaSales = () => {
    const [name, setName] = useState("");
    const [dayPlacedOrder, setDayPlacedOrder] = useState("");
    const [dayToBeDelivered, setDayToBeDelivered] = useState("");
    const [quantity, setQuantity] = useState("");
    const [salerName, setSalerName] = useState("");
    const [styles, setStyles] = useState([]);
    const [configuration, setConfiguration] = useState([]);
    const [minDate, setMinDate] = useState(
        new Date().toISOString().split("T")[0]
    );

    const [selectedCustomer, setSelectedCustomer] = useState("");
    const [selectedCustomerId, setSelectCustomerId] = useState("");
    const [selectedName, setSelectedName] = useState("");
    const [selectedGroup, setSelectedGroup] = useState("");
    const [selectedFamily, setSelectedFamily] = useState("");
    const [selectedProduct, setSelectedProduct] = useState("");
    const [selectedColor, setSelectedColor] = useState("");
    const [selectedStyle, setSelectedStyle] = useState("NA");
    const [selectedConfiguration, setSelectedConfigutation] = useState("NA");
    const [selectedCode, setSelectedCode] = useState("");
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState({});

    const [groups, setGroups] = useState([]);
    const [clients, setClients] = useState([]);
    const [groupsId, setGroupsId] = useState([]);
    const [products, setProducts] = useState([]);

    const animatedComponents = makeAnimated();

    const [errorMessage, setErrorMessage] = useState({
        name: "",
        customerName: "",
        customerId: "",
        dayPlacedOrder: "",
        dayToBeDelivered: "",
        productCode: "",
        quantity: "",
        groups: "",
        family: "",
        product: "",
        color: "",
        emptyData: "",
        emptyOrder: "",
        styles: "",
        configuration: "",
    });

    useEffect(() => {
        var group = [];
        Object.keys(catalogData).map((key) => group.push(key));
        setGroups(group);
    }, []);

    useEffect(() => {
        var groupId = [];
        Object.keys(catalogId).map((key) => groupId.push(key));
        setGroupsId(groupId);
    }, []);

    useEffect(() => {
        var client = [];
        Object.keys(clientsData).map((key) =>
            client.push([
                clientsData[key].OrganizationName,
                clientsData[key].CustomerAccount,
            ])
        );
        setClients(client);
        var styles = [];
        Object.keys(stylesData).map((key) => styles.push(stylesData[key].StyleId));
        setStyles(styles);
        var configuration = [];
        Object.keys(configurationData).map((key) =>
            configuration.push(configurationData[key].configuracion)
        );
        setConfiguration(configuration);
    }, []);

    useEffect(() => {
        if (selectedProduct) {
            catalogData[selectedGroup][selectedFamily].map((option) =>
                option.name === selectedProduct ? setSelectedCode(option.id) : null
            );
        }
    }, [selectedGroup, selectedFamily, selectedProduct]);

    function getCodes(type, filter) {
        var group = [];
        if (type === 1) {
            Object.keys(catalogId).map((key) => group.push(key));
            setGroupsId(group);
        } else if (type === 2) {
            Object.keys(catalogId).map((key) =>
                catalogId[key].grupo === filter ? group.push(key) : null
            );
            setGroupsId(group);
        } else if (type === 3) {
            Object.keys(catalogId).map((key) =>
                catalogId[key].familia === filter &&
                catalogId[key].grupo === selectedGroup
                    ? group.push(key)
                    : null
            );
            setGroupsId(group);
        } else if (type === 4) {
            Object.keys(catalogId).map((key) =>
                catalogId[key].familia === selectedFamily &&
                catalogId[key].grupo === selectedGroup &&
                catalogId[key].name === filter
                    ? group.push(key)
                    : null
            );
            setGroupsId(group);
        }
    }

    const handleCustomerChange = (e) => {
        if (e.length === 0) {
            setSelectCustomerId("");
            setSelectedCustomer("");
            setErrorMessage({
                ...errorMessage,
                ["customerId"]: "Ingrese un valor válido",
            });
            return;
        }
        setSelectedCustomer(e);
        setErrorMessage({...errorMessage, ["customerId"]: ""});
        setErrorMessage({...errorMessage, ["customerName"]: ""});
        clients.find((option) =>
            option[0] === e ? setSelectCustomerId(option[1]) : null
        );
    };

    const handleCustomerChangeId = (e) => {
        if (e.length === 0) {
            setSelectCustomerId("");
            setSelectedCustomer("");
            setErrorMessage({
                ...errorMessage,
                ["customerName"]: "Ingrese un valor válido",
            });
            return;
        }
        setSelectCustomerId(e);
        setErrorMessage({...errorMessage, ["customerId"]: ""});
        setErrorMessage({...errorMessage, ["customerName"]: ""});
        clients.find((option) =>
            option[1] === e ? setSelectedCustomer(option[0]) : null
        );
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        var errors = false;
        const order = {
            vendedor: "",
            comprador: "",
            id_comprador: "",
            fecha_orden: "",
            fecha_entrega: "",
            pedidos: [],
        };
        order.vendedor = salerName;
        order.comprador = selectedCustomer;
        order.fecha_orden = dayPlacedOrder;
        order.fecha_entrega = dayToBeDelivered;
        order.pedidos = selectedProducts;
        order.id_comprador = selectedCustomerId;
        if (
            salerName === "" ||
            selectedCustomer === "" ||
            selectedCustomerId === "" ||
            dayPlacedOrder === "" ||
            dayToBeDelivered === "" ||
            selectedProducts.length === 0
        ) {
            errors = true;
        }
        order.pedidos.map((Object) =>
            Object.codigo === "" ||
            Object.cantidad === 0 ||
            Object.grupo === "" ||
            Object.familia === "" ||
            Object.producto === "" ||
            Object.color === ""
                ? (errors = true)
                : null
        );
        if (errors) {
            setErrorMessage({
                ...errorMessage,
                ["emptyData"]: "*Revise y/o llene todos los campos antes de enviar el formulario",
            });
            return;
        }
        setErrorMessage({...errorMessage, ["emptyData"]: ""});
        //TODO codigo para enviar el codigo
        console.log("Orden enviada...");
        console.log(order);
    };

    /*
        const sendEmailResponse = await ses.sendEmail(emailParams).promise();
        // Reset form fields after successful submission
        setName('');
        setDayPlacedOrder('');
        setDayToBeDelivered('');
        setQuantity('');
        setSelectedGroup('');
        setSelectedFamily('');
        setSelectedProduct('');
        setSelectedColor('');
        setSelectedCode('');
      } catch (error) {
        console.error('Error sending email:', error);
      }
    }; */

    const addOrder = (e) => {
        e.preventDefault();
        var errors = false
        var product = {
            codigo: "",
            cantidad: 0,
            grupo: "",
            familia: "",
            producto: "",
            color: "",
            estilo: "",
            configuracion: "",
        };
        if (
            selectedCode === "" ||
            quantity === "" ||
            selectedGroup === "" ||
            selectedFamily === "" ||
            selectedProduct === "" ||
            selectedColor === ""
        ) {
            errors = true;
        }
        if (errors) {
            setErrorMessage({
                ...errorMessage,
                ["emptyOrder"]: "*Revise y/o llene todos los campos antes de añadir una orden",
            });
            return;
        }
        product.codigo = selectedCode;
        product.grupo = selectedFamily;
        product.familia = selectedGroup;
        product.producto = selectedProduct;
        product.color = selectedColor;
        product.cantidad = quantity;
        product.estilo = selectedStyle;
        product.configuracion = selectedConfiguration;
        setSelectedProducts([...selectedProducts, product]);
        setErrorMessage({...errorMessage, ["emptyOrder"]: ""});
    };

    const handleTextChange = (e, label) => {
        if (e.target.value === "" || e.target.value === null || e.target.value === undefined || e.target.value.length === 0) {
            setErrorMessage({...errorMessage, [label]: "Ingrese un valor válido"});
            if (e.target.value === "") {
                if (label === "vendor") {
                    setSalerName("");
                } else if (label === "quantity") {
                    setQuantity("");
                }
            }
            return;
        }
        switch (label) {
            case "vendor":
                setSalerName(e.target.value);
                setErrorMessage({...errorMessage, [label]: ""});
                break;
            case "quantity":
                console.log(e.target.value)
                if (e.target.value <= 0) {
                    setErrorMessage({
                        ...errorMessage,
                        [label]: "Ingrese un valor mayor a 0",
                    });
                    return;
                } else if (e.target.value % 1 !== 0) {
                    setErrorMessage({
                        ...errorMessage,
                        [label]: "Ingrese un valor válido",
                    });
                    return;
                }
                setQuantity(e.target.value);
                setErrorMessage({...errorMessage, [label]: ""});
                break;
        }
    }
    const handleInputChange = (e, label) => {
        console.log(e)
        if (e.value === "" || e.value === null || e.value === undefined || e.value.length === 0) {
            setErrorMessage({...errorMessage, [label]: "Ingrese un valor válido"});
            return;
        }
        switch (label) {
            case "color":
                console.log(e.value)
                setSelectedColor(e.value);
                break;
            case "group":
                setSelectedGroup(e.value);
                setSelectedFamily("");
                setSelectedProduct("");
                setSelectedColor("");
                setSelectedCode("");
                if (e.value === "") {
                    getCodes(1, "");
                } else {
                    getCodes(2, e);
                }
                break;
            case "buyer":
                if (e.value === 0) {
                    setSelectCustomerId("");
                    setSelectedCustomer("");
                    setErrorMessage({
                        ...errorMessage,
                        ["customerId"]: "Ingrese un valor válido",
                    });
                    return;
                }
                setSelectedCustomer(e.value);
                setErrorMessage({...errorMessage, ["customerId"]: ""});
                setErrorMessage({...errorMessage, ["customerName"]: ""});
                clients.find((option) =>
                    option[0] === e.value ? setSelectCustomerId(
                        option[1]
                    ) : null
                );
                break;
            case "customerId":
                if (e.value === 0) {
                    setSelectCustomerId("");
                    setSelectedCustomer("");
                    setErrorMessage({
                        ...errorMessage,
                        ["customerName"]: "Ingrese un valor válido",
                    });
                    return;
                }
                setSelectCustomerId(e.value);
                setErrorMessage({...errorMessage, ["customerId"]: ""});
                setErrorMessage({...errorMessage, ["customerName"]: ""});
                clients.find((option) =>
                    option[1] === e.value ? setSelectedCustomer(
                        option[0]
                    )  : null
                    //option[1] === e.value ? console.log(option[0])  : null
                );
                break;
            case "productCode":
                setSelectedCode(e.value);
                Object.keys(catalogId).map((key) =>
                    key === e.value
                        ? (setSelectedGroup(catalogId[key].grupo),
                            setSelectedFamily(catalogId[key].familia),
                            setSelectedProduct(catalogId[key].name),
                            setProducts(
                                catalogData[catalogId[key].grupo][catalogId[key].familia]
                                    .map((item) => item.name)
                                    .filter((value, index, self) => self.indexOf(value) === index)
                            ))
                        : null
                );
                break;
            case "family":
                setSelectedFamily(e.value);
                setSelectedProduct("");
                setSelectedColor("");
                setSelectedCode("");
                if (e.value === 0) {
                    return;
                }
                const products = catalogData[selectedGroup][e.value]
                    .map((item) => item.name)
                    .filter((value, index, self) => self.indexOf(value) === index);
                setProducts(products);
                if (e === "") {
                    getCodes(2, selectedGroup);
                } else {
                    getCodes(3, e.value);
                }
                break;
            case "product":
                console.log(e)
                setSelectedProduct(e.value);
                setSelectedColor("");
                setSelectedCode("");
                if (e === "") {
                    getCodes(3, selectedFamily);
                } else {
                    getCodes(4, e.value);
                }
                break;
            case "style":
                setSelectedStyle(e.value);
                break;
            case "configuration":
                setSelectedConfigutation(e.value);
                break;
        }


        if (label === "quantity") {
            if (e.value <= 0) {
                setErrorMessage({
                    ...errorMessage,
                    [label]: "Ingrese un valor mayor a 0",
                });
                return;
            } else if (e.value % 1 !== 0) {
                setErrorMessage({
                    ...errorMessage,
                    [label]: "Ingrese un valor válido",
                });
                return;
            }
        }
        setErrorMessage({...errorMessage, [label]: ""});
    };
    const handleDate = (e) => {
        const date = new Date(e);
        const deliveredDate = new Date(date.setDate(date.getDate() + 15));
        setDayPlacedOrder(e);
        setMinDate(
            new Date(new Date(e).setDate(new Date(e).getDate() + 15))
                .toISOString()
                .split("T")[0]
        );
        if (dayToBeDelivered !== "") {
            if (deliveredDate > new Date(dayToBeDelivered)) {
                setDayToBeDelivered(deliveredDate.toISOString().split("T")[0]);
            }
        }
    };

    const handleGroupChange = (e) => {
        setSelectedGroup(e);
        setSelectedFamily("");
        setSelectedProduct("");
        setSelectedColor("");
        setSelectedCode("");
        if (e === "") {
            getCodes(1, "");
        } else {
            getCodes(2, e);
        }
    };

    const handleFamilyChange = (e) => {
        setSelectedFamily(e);
        setSelectedProduct("");
        setSelectedColor("");
        setSelectedCode("");
        if (e.length === 0) {
            return;
        }
        const products = catalogData[selectedGroup][e]
            .map((item) => item.name)
            .filter((value, index, self) => self.indexOf(value) === index);
        setProducts(products);
        if (e === "") {
            getCodes(2, selectedGroup);
        } else {
            getCodes(3, e);
        }
    };

    const handleProductChange = (e) => {
        setSelectedProduct(e);
        setSelectedColor("");
        setSelectedCode("");
        if (e === "") {
            getCodes(3, selectedFamily);
        } else {
            getCodes(4, e);
        }
    };

    const handleCodeChange = (e) => {
        setSelectedCode(e);
        Object.keys(catalogId).map((key) =>
            key === e
                ? (setSelectedGroup(catalogId[key].grupo),
                    setSelectedFamily(catalogId[key].familia),
                    setSelectedProduct(catalogId[key].name),
                    setProducts(
                        catalogData[catalogId[key].grupo][catalogId[key].familia]
                            .map((item) => item.name)
                            .filter((value, index, self) => self.indexOf(value) === index)
                    ))
                : null
        );
    };

    const bodyTable = selectedProducts.map((product, index) => (
        <tr key={index}>
            <td className="text-center">{product.codigo}</td>
            <td className="text-center">{product.producto}</td>
            <td className="text-center">{product.color}</td>
            <td className="text-center">
        <span
            className="material-symbols-outlined delete_order"
            onClick={() =>
                setSelectedProducts(
                    selectedProducts.filter((item) => item.codigo !== product.codigo)
                )
            }
        >
          delete
        </span>
            </td>
        </tr>
    ));

    return (
        <>
            <div className="min-vh-100 d-flex justify-content-center align-items-center g-container ">
                <div className="header container">
                    <div className="row header_container">
                        <footer className="row d-flex justify-content-center">
                            <img src="./images/Mega.png" alt="power by improgress"/>
                        </footer>
                        <h3 className="text-center title row">Nuevo pedido</h3>
                    </div>
                </div>
                <form
                    className="form-container d-flex flex-column form_container"
                    id="lost-sale">
                    <span className="divider mb-4"></span>

                    {/* VENDOR NAME */}
                    <div className="mb-3">
                        <label htmlFor="marca" className="form-label">Nombre del vendedor<span className="text-danger p-2">*</span></label>
                        <input
                            type="text"
                            id="salerName"
                            name="salerName"
                            className="form-control"
                            autoComplete="off"
                            value={salerName}
                            onChange={(e) => handleTextChange(e, "vendor")}
                        />
                        {errorMessage["name"] ? <div className="error_text">{errorMessage["name"]}</div> : null}
                    </div>

                    {/* BUYER NAME */}
                    <div className="mb-3 position-relative">
                        <label htmlFor="marca" className="form-label">Nombre del comprador<span className="text-danger p-2">*</span></label>

                        <Select
                            options={clients.map(client => ({value: client[0], label: client[0]}))}
                            components={animatedComponents}
                            onChange={(selectedOption) =>
                                handleInputChange(selectedOption, "buyer")
                            }
                            value={{
                                value: selectedCustomer,
                                label: selectedCustomer,
                            }}

                            placeholder='Seleccione el comprador...'
                        />

                        {/*<select*/}
                        {/*    type="text"*/}
                        {/*    id="name"*/}
                        {/*    name="name"*/}
                        {/*    className="form-control"*/}
                        {/*    autoComplete="off"*/}
                        {/*    value={selectedCustomer}*/}
                        {/*    //onChange={(e) => handleCustomerChange(e)}*/}
                        {/*    onChange={(e) =>*/}
                        {/*        handleInputChange(e, "customerName", handleCustomerChange)*/}
                        {/*    }*/}
                        {/*>*/}
                        {/*    <option defaultValue hidden>*/}
                        {/*        Seleccione comprador*/}
                        {/*    </option>*/}
                        {/*    {clients.map((option, index) => (*/}
                        {/*        <option key={index} value={option[0]}>*/}
                        {/*            {option[0]}*/}
                        {/*        </option>*/}
                        {/*    ))}*/}
                        {/*</select>*/}

                        {errorMessage["customerName"] ? <div className="error_text">{errorMessage["customerName"]}</div> : null }
                    </div>

                    {/* BUYER CODE */}
                    <div className="mb-3 position-relative">
                        <label htmlFor="marca" className="form-label">Código del comprador<span className="text-danger p-2">*</span></label>
                        <Select
                            options={clients.map(client => ({value: client[1], label: client[1]}))}
                            components={animatedComponents}
                            onChange={(selectedOption) =>
                                handleInputChange(selectedOption, "customerId")
                            }
                            value={{
                                value: selectedCustomerId,
                                label: selectedCustomerId,
                        }}
                            placeholder='Seleccione el codigo del comprador...'
                        />

                        {/*<select*/}
                        {/*    type="text"*/}
                        {/*    id="nameId"*/}
                        {/*    name="nameId"*/}
                        {/*    className="form-control"*/}
                        {/*    autoComplete="off"*/}
                        {/*    value={selectedCustomerId}*/}
                        {/*    //onChange={(e) => handleCustomerChangeId(e)}*/}
                        {/*    onChange={(e) =>*/}
                        {/*        handleInputChange(e, "customerId", handleCustomerChangeId)*/}
                        {/*    }*/}
                        {/*>*/}
                        {/*    <option defaultValue hidden>*/}
                        {/*        Seleccione Id del comprador*/}
                        {/*    </option>*/}
                        {/*    {clients.map((option, index) => (*/}
                        {/*        <option key={index} value={option[1]}>*/}
                        {/*            {option[1]}*/}
                        {/*        </option>*/}
                        {/*    ))}*/}
                        {/*</select>*/}

                        {errorMessage["customerId"] ? <div className="error_text">{errorMessage["customerId"]}</div> : null}
                    </div>


                    {/* ORDER DAY */}
                    <div className="mb-3 position-relative">
                        <label htmlFor="marca" className="form-label">Día de orden<span className="text-danger p-2">*</span></label>
                        <input
                            type="date"
                            id="dayPlacedOrder"
                            name="placedOrder"
                            className="form-control"
                            min={new Date().toISOString().split("T")[0]}
                            autoComplete="off"
                            value={dayPlacedOrder}
                            onChange={(e) => handleDate(e.target.value)}
                            //onChange={(e) =>
                            //   handleInputChange(e, "dayPlacedOrder", handleDate)
                            //}
                        />
                        {errorMessage["dayPlacedOrder"] ? <div className="error_text">{errorMessage["dayPlacedOrder"]}</div> : null}
                    </div>

                    {/* DELIVERY DAY */}
                    <div className="mb-3 position-relative">
                        <label htmlFor="marca" className="form-label">Día de entrega<span className="text-danger p-2">*</span></label>
                        <input
                            type="date"
                            id="dayToBeDelivered"
                            name="dayToBeDelivered"
                            className="form-control"
                            autoComplete="off"
                            min={minDate}
                            value={dayToBeDelivered}
                            onChange={(e) => setDayToBeDelivered(e.target.value)}
                            //onChange={(e) =>
                            //    handleInputChange(e, "dayToBeDelivered", setDayToBeDelivered)
                            //}
                        />
                        {errorMessage["dayToBeDelivered"] ? <div className="error_text">{errorMessage["dayToBeDelivered"]}</div> : null}
                    </div>

                    {/* CODE */}
                    <div className="mb-3 position-relative">
                        <label htmlFor="marca" className="form-label">Código<span className="text-danger p-2">*</span></label>
                        <Select
                            options={groupsId.map(group => ({value: group, label: group}))}
                            components={animatedComponents}
                            onChange={(selectedOption) =>
                                handleInputChange(selectedOption, "productCode")
                            }
                            value={{
                                value: selectedCode,
                                label: selectedCode,
                            }}
                            placeholder='Seleccione una familia...'
                        />

                        {/*<select*/}
                        {/*    type="text"*/}
                        {/*    id="code"*/}
                        {/*    name="code"*/}
                        {/*    className="form-control"*/}
                        {/*    autoComplete="off"*/}
                        {/*    value={selectedCode}*/}
                        {/*    //onChange={(e) => handleCodeChange(e)}*/}
                        {/*    onChange={(e) =>*/}
                        {/*        handleInputChange(e, "productCode", handleCodeChange)*/}
                        {/*    }*/}
                        {/*>*/}
                        {/*    <option defaultValue hidden>*/}
                        {/*        Seleccione código*/}
                        {/*    </option>*/}
                        {/*    {groupsId.map((option, index) => (*/}
                        {/*        <option key={index} value={option}>*/}
                        {/*            {option}*/}
                        {/*        </option>*/}
                        {/*    ))}*/}
                        {/*</select>*/}

                        {errorMessage["productCode"] ? <div className="error_text">{errorMessage["productCode"]}</div> : null}
                    </div>

                    {/* QUANTITY */}
                    <div className="mb-3 position-relative">
                        <label htmlFor="marca" className="form-label">Cantidad<span className="text-danger p-2">*</span></label>
                        <input
                            type="number"
                            id="quantity"
                            name="quantity"
                            className="form-control"
                            autoComplete="off"
                            value={quantity}
                            onChange={(e) => handleTextChange(e, "quantity")}
                        />
                        {errorMessage["quantity"] ? <div className="error_text">{errorMessage["quantity"]}</div> : null}
                    </div>

                    {/* GROUP */}
                    <div className="mb-3 position-relative">
                        <label htmlFor="marca" className="form-label">Grupo <span className="text-danger p-2">*</span></label>

                        <Select
                            options={groups.map(group => ({value: group, label: group}))}
                            components={animatedComponents}
                            onChange={(selectedOption) =>
                                handleInputChange(selectedOption, "group")
                            }
                            value={{
                                value: selectedGroup,
                                label: selectedGroup,
                            }}
                            placeholder='Seleccione un grupo...'
                        />

                        {/*<select*/}
                        {/*    type="text"*/}
                        {/*    id="group"*/}
                        {/*    name="group"*/}
                        {/*    className="form-control"*/}
                        {/*    autoComplete="off"*/}
                        {/*    value={selectedGroup}*/}
                        {/*    //onChange={(e) => handleGroupChange(e)}*/}
                        {/*    onChange={(e) =>*/}
                        {/*        handleInputChange(e, "groups", handleGroupChange)*/}
                        {/*    }*/}
                        {/*>*/}
                        {/*    <option defaultValue hidden>*/}
                        {/*        Seleccione familia*/}
                        {/*    </option>*/}
                        {/*    {groups.map((option, index) => (*/}
                        {/*        <option key={index} value={option}>*/}
                        {/*            {option}*/}
                        {/*        </option>*/}
                        {/*    ))}*/}
                        {/*</select>*/}

                        {errorMessage["groups"] ? <div className="error_text">{errorMessage["groups"]}</div> : null}
                    </div>

                    {/* FAMILY */}
                    {selectedGroup && (
                        <div className="mb-3 position-relative"><label htmlFor="marca" className="form-label">Familia<span className="text-danger p-2">*</span></label>

                            <Select
                                options={Object.keys(catalogData[selectedGroup]).map(option => ({value: option, label: option}))}
                                components={animatedComponents}
                                onChange={(selectedOption) =>
                                    handleInputChange(selectedOption, "family")
                                }
                                value={{
                                    value: selectedFamily,
                                    label: selectedFamily,
                                }}
                                placeholder='Seleccione una familia...'
                            />

                            {/*<select*/}
                            {/*    type="text"*/}
                            {/*    id="family"*/}
                            {/*    name="family"*/}
                            {/*    className="form-control"*/}
                            {/*    autoComplete="off"*/}
                            {/*    value={selectedFamily}*/}
                            {/*    //onChange={(e) => handleFamilyChange(e)}*/}
                            {/*    onChange={(e) =>*/}
                            {/*        handleInputChange(e, "family", handleFamilyChange)*/}
                            {/*    }*/}
                            {/*>*/}
                            {/*    <option defaultValue hidden>*/}
                            {/*        Seleccione grupo*/}
                            {/*    </option>*/}
                            {/*    {Object.keys(catalogData[selectedGroup]).map(*/}
                            {/*        (option, index) => (*/}
                            {/*            <option key={index} value={option}>*/}
                            {/*                {option}*/}
                            {/*            </option>*/}
                            {/*        )*/}
                            {/*    )}*/}
                            {/*</select>*/}

                            {errorMessage["family"] ? <div className="error_text">{errorMessage["family"]}</div> : null}
                        </div>
                    )}

                    {/* PRODUCT */}
                    {selectedFamily && (
                        <div className="mb-3 position-relative"><label htmlFor="marca" className="form-label">Producto<span className="text-danger p-2">*</span></label>

                            <Select
                                options={products.map(option => ({value: option, label: option}))}
                                components={animatedComponents}
                                onChange={(selectedOption) =>
                                    handleInputChange(selectedOption, "product")
                                }
                                value={{
                                    value: selectedProduct,
                                    label: selectedProduct,
                                }}
                                placeholder='Seleccione una producto...'
                            />


                            {/*<select*/}
                            {/*    type="text"*/}
                            {/*    id="family"*/}
                            {/*    name="family"*/}
                            {/*    className="form-control"*/}
                            {/*    autoComplete="off"*/}
                            {/*    value={selectedProduct}*/}
                            {/*    //onChange={(e) => handleProductChange(e)}*/}
                            {/*    onChange={(e) =>*/}
                            {/*        handleInputChange(e, "product", handleProductChange)*/}
                            {/*    }*/}
                            {/*>*/}
                            {/*    <option defaultValue hidden>*/}
                            {/*        Seleccione producto*/}
                            {/*    </option>*/}
                            {/*    {products.map((option, index) => (*/}
                            {/*        <option key={index} value={option.name}>*/}
                            {/*            {option}*/}
                            {/*        </option>*/}
                            {/*    ))}*/}
                            {/*</select>*/}

                            {errorMessage["product"] ? <div className="error_text">{errorMessage["product"]}</div>: null}

                        </div>
                    )}

                    {selectedProduct && (
                        <>
                            {/* COLOR */}
                            <div className="mb-3 position-relative"><label htmlFor="marca" className="form-label">Color<span className="text-danger p-2">*</span></label>
                                <Select
                                    options={catalogData[selectedGroup][selectedFamily].map(
                                        (option) =>
                                            option.name === selectedProduct &&
                                            option.id === selectedCode
                                                ? option.colors.map((color, index) => ({
                                                    value: color.name,
                                                    label: color.name,
                                                }))
                                                : null
                                    )
                                        .filter((value) => value !== null)
                                        .flat()}
                                    components={animatedComponents}
                                    //onChange={(e) => handleColorChange(e.value)}
                                    onChange={(selectedOption) =>
                                        handleInputChange(selectedOption, "color")
                                    }
                                    value={{
                                        value: selectedColor,
                                        label: selectedColor,
                                    }}
                                    placeholder='Seleccione...'
                                />
                                {/*<select*/}
                                {/*  type="search"*/}
                                {/*  id="color"*/}
                                {/*  name="color"*/}
                                {/*  className="form-control"*/}
                                {/*  autoComplete="off"*/}
                                {/*  value={selectedColor}*/}
                                {/*  //onChange={(e) => handleColorChange(e)}*/}
                                {/*  onChange={(e) =>*/}
                                {/*    handleInputChange(e, "color", handleColorChange)*/}
                                {/*  }*/}
                                {/*>*/}
                                {/*  <option defaultValue hidden>*/}
                                {/*    Seleccione color*/}
                                {/*  </option>*/}
                                {/*  {catalogData[selectedGroup][selectedFamily].map((option) =>*/}
                                {/*    option.name === selectedProduct &&*/}
                                {/*    option.id === selectedCode*/}
                                {/*      ? option.colors.map((color, index) => (*/}
                                {/*          <option key={index} value={color.name}>*/}
                                {/*            {color.name}*/}
                                {/*          </option>*/}
                                {/*        ))*/}
                                {/*      : null*/}
                                {/*  )}*/}
                                {/*</select>*/}

                                {errorMessage["color"] ? <div className="error_text ">{errorMessage["color"]}</div> : null}
                            </div>

                            {/* STYLE */}
                            <div className="mb-3 position-relative">
                                <label htmlFor="marca" className="form-label">Estilo</label>

                                <Select
                                    options={styles.map(option => ({value: option, label: option}))}
                                    components={animatedComponents}
                                    onChange={(selectedOption) =>
                                        handleInputChange(selectedOption, "style")
                                    }
                                    value={{
                                        value: selectedStyle,
                                        label: selectedStyle,
                                    }}
                                    placeholder='Seleccione el estilo...'
                                />

                                {/*<select*/}
                                {/*    type="text"*/}
                                {/*    id="style"*/}
                                {/*    name="style"*/}
                                {/*    className="form-control"*/}
                                {/*    autoComplete="off"*/}
                                {/*    value={selectedStyle}*/}
                                {/*    //onChange={(e) => handleColorChange(e)}*/}
                                {/*    onChange={(e) =>*/}
                                {/*        handleInputChange(e, "style", setSelectedStyle)*/}
                                {/*    }*/}
                                {/*>*/}
                                {/*    <option defaultValue hidden>*/}
                                {/*        Seleccione estilo*/}
                                {/*    </option>*/}
                                {/*    {styles.map((option, index) => (*/}
                                {/*        <option key={index} value={option}>*/}
                                {/*            {option}*/}
                                {/*        </option>*/}
                                {/*    ))}*/}
                                {/*</select>*/}

                                {errorMessage["styles"] ? <div className="error_text ">{errorMessage["color"]}</div> : null}
                            </div>

                            {/* CONFIGURATION */}
                            <div className="mb-3 position-relative">
                                <label htmlFor="marca" className="form-label">Configuración</label>

                                <Select
                                    options={configuration.map(option => ({value: option, label: option}))}
                                    components={animatedComponents}
                                    //onChange={(e) => handleColorChange(e.value)}
                                    onChange={(selectedOption) =>
                                        handleInputChange(selectedOption, "configuration")
                                    }
                                    value={{
                                        value: selectedConfiguration,
                                        label: selectedConfiguration,
                                    }}
                                    placeholder='Seleccione...'
                                />

                                {/*<select*/}
                                {/*    type="text"*/}
                                {/*    id="configuration"*/}
                                {/*    name="configuration"*/}
                                {/*    className="form-control"*/}
                                {/*    autoComplete="off"*/}
                                {/*    value={selectedConfiguration}*/}
                                {/*    //onChange={(e) => handleColorChange(e)}*/}
                                {/*    onChange={(e) =>*/}
                                {/*        handleInputChange(*/}
                                {/*            e,*/}
                                {/*            "configuration",*/}
                                {/*            setSelectedConfigutation*/}
                                {/*        )*/}
                                {/*    }*/}
                                {/*>*/}
                                {/*    <option defaultValue hidden>*/}
                                {/*        Seleccione configuracion*/}
                                {/*    </option>*/}
                                {/*    {configuration.map((option, index) => (*/}
                                {/*        <option key={index} value={option}>*/}
                                {/*            {option}*/}
                                {/*        </option>*/}
                                {/*    ))}*/}
                                {/*</select>*/}

                                {errorMessage["configuration"] ? <div className="error_text ">{errorMessage["color"]}</div> : null}
                            </div>
                        </>
                    )}

                    <span className="material-symbols-outlined add_order" onClick={(e) => addOrder(e)}> add</span>

                    <table className="table">
                        <thead>
                        <tr>
                            <th className="text-center" scope="col">
                                Código
                            </th>
                            <th className="text-center" scope="col">
                                Descripción
                            </th>
                            <th className="text-center" scope="col">
                                Color
                            </th>
                            <th className="text-center" scope="col"></th>
                        </tr>
                        </thead>
                        <tbody className="table-group-divider">{bodyTable}</tbody>
                    </table>
                    {errorMessage["emptyOrder"] ? <div className="error_text text-center">{errorMessage["emptyOrder"]}</div> : null}

                    <div className="mt-3 d-flex justify-content-between">
                        <div className="w-50 mx-2">
                            <Link to="/home">
                                <button className="w-100 btn btn-outline-secondary py-2 justify-self-end">
                                    Regresar
                                </button>
                            </Link>
                        </div>
                        <div className="w-50 mx-2">
                            <button className="w-100 btn btn-primary py-2 justify-self-end" onClick={(e) => handleSubmit(e)}>
                                Enviar
                            </button>
                        </div>
                    </div>

                    {errorMessage["emptyData"] ? <div className="error_text text-center">{errorMessage["emptyData"]}</div> : null}

                    <span className="divider mb-4"></span>
                    <footer className="d-flex justify-content-center powerby">
                        <img src="./images/powerBy2.png" alt="power by improgress"/>
                    </footer>
                    <div> <p className="versionText">v 1.0.0</p> </div>
                </form>
            </div>
        </>
    );
};

export default MegaSales;
