import React, { useEffect, useState } from "react";
import "./data.css";
import WorkloadMovile from "./WorkloadMobile";
import WorkloadDesktop from "./WorkloadDesktop";

const Workload = () => {

  const [width, setWidth] = useState(window.innerWidth);

  return (
    <>
      {width > 768 ? <WorkloadDesktop/> : <WorkloadMovile/>}
    </>
  )
};
export default Workload;
