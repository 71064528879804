export const request = async (route) => {

    try {
        const response = await fetch(route, {
            method: 'GET',
            headers: {'Accept': 'application/json'},
        });

        return response.json();

    } catch (error) {
        console.error('There was a problem with the fetch operation: ' + error.message);
    }
}


export const requestpost = async (route) => {

    try {
        const response = await fetch(route, {
            method: 'POST',
            headers: {'Accept': 'application/json'},
        });

        return response.json();

    } catch (error) {
        console.error('There was a problem with the fetch operation: ' + error.message);
    }
}
