import React, { useEffect, useState } from "react";
import "./megaGraph.css";
import { Navbar } from "../Navbar/Navbar";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  ReferenceLine,
  Bar,
  BarChart,
  Cell,
  LabelList
} from "recharts";
import { requestpost } from "../Data/Requests";
import pesos_inventario2023 from "./pesos_inventario2023.json";


const MegaGraph = () => {
  const [pesosData, setPesosData] = useState([]);

  useEffect(() => {
    // requestpost(`http://meg-gt.improgress.net/api/consult_pesos_inventario`).then(
    requestpost(`https://meg-gt.improgress.net/api/consult_pesos_inventario`).then(
      (response) => {
        setPesosData(pesos_inventario2023.concat(response[0]));
        // setPesosData(response[0]);
        console.log("json4", JSON.stringify(response[0]));
        // TODO DELETE
      }
    );
  }, []);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="shadow rounded bg-light px-2 pt-1">
          <p className="m-0 mb-1 p-0 fw-semibold border-bottom">{label}</p>
          <p className="m-0 p-0" style={{ color: payload[0].color }}>
            <strong> {payload[0].name} </strong>:{" "}
            {parseFloat(payload[0].value).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </p>
        </div>
      );
    }
  };

  const CustomTooltipPRD = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="shadow rounded bg-light px-2 pt-1">
          <p className="m-0 mb-1 p-0 fw-semibold border-bottom">{label}</p>
          <p className="m-0 p-0" style={{ color: payload[0].color }}>
            <strong> {payload[0].name} </strong>: {payload[0].value}kg
          </p>
        </div>
      );
    }
  };

  // eslint-disable-next-line
  const formatYAxis = (value) => {
    return parseFloat(value).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  const renderCustomLabel = (props) => {
    const { x, y, value } = props;
    const formattedValue = (
        parseInt(value).toLocaleString("es-GT", {
        style: "currency",
        currency: "GTQ",
        maximumFractionDigits: 0,
       
      })
    );
    if (props.index === pesosData.length -1) {
      return (
        <g>
          <rect x={x-20} y={y - 23} width={40} height={16} fill='white' />
          <text
            x={x+18}
            y={y}
            dy={-10}
            fill='#333'
            fontSize={12}
            textAnchor='middle'
          >
            {formattedValue}
          </text>
        </g>
      );
    }

    return null;
};

const renderCustomLabel2 = (props) => {
    const { x, y, value } = props;
    const formattedValue = (
        parseInt(value).toLocaleString("es-GT") + " Kg"
    );
    if (props.index === pesosData.length -1) {
      return (
        <g>
          <rect x={x-20} y={y - 23} width={40} height={16} fill='white' />
          <text
            x={x+20}
            y={y}
            dy={-10}
            fill='#333'
            fontSize={12}
            textAnchor='middle'
          >
            {formattedValue}
          </text>
        </g>
      );
    }

    return null;
};

const bodyTable = pesosData.map((entry, index) => (
  <tr key={index}>
    <th className="text-center vertical-divider" scope="row">
      {entry.name}
    </th>
    <td className="text-center">
      {" "}
      {parseInt(entry["sls.22"]).toLocaleString("es-GT", {
        style: "currency",
        currency: "GTQ",
        maximumFractionDigits: 0,
      })}
    </td>
    <td className="text-center">
      {parseInt(entry["sls.23"]).toLocaleString("es-GT", {
        style: "currency",
        currency: "GTQ",
        maximumFractionDigits: 0,
      })}
    </td>
    <td className="text-center vertical-divider"  style={{
                  color: entry["sls.d"] < 0 ? "red" : "green",
                  fontWeight: "bold",
              }}>
      {parseInt(entry["sls.d"]).toLocaleString("es-GT", {
        style: "currency",
        currency: "GTQ",
        maximumFractionDigits: 0,
       
      })}
    </td>
    <td className="text-center">
      {parseInt(entry["prd.22"]).toLocaleString("en-US")} Kg
    </td>
    <td className="text-center">
      {parseInt(entry["prd.23"]).toLocaleString("en-US")} Kg
    </td>
    <td
              className="text-center"
              style={{
                  color: entry["prd.d"] < 0 ? "red" : "green",
                  fontWeight: "bold",
              }}
          >
              {((entry["prd.d"]*100)/100).toFixed(2).toLocaleString("en-US")} Kg
          </td>
  </tr>
));

  const DataFormatter = (value) => {
    if (value > 1000000) {
      return (value / 1000000).toFixed(1);
    } else if (value > 1000) {
      return (value / 1000).toFixed(1);
    } else if (value < -1000000) {
      return (value / 1000000).toFixed(1);
    } else if (value < -1000) {
      return (value / 1000).toFixed(1);
    } else return value;
  };
  const dataWithDifference = pesosData.map((item) => ({
    ...item,
    "sls.d": item["sls.23"] - item["sls.22"],
  }) 
  );


  return (
    <>
      <Navbar />
      { pesosData.length === 0 ? 
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div> : <><div className="m-0 m-md-4 row row-cols-1 row-cols-md-2">
          <div>
            {pesosData.length === 0 ? <h3 className="mb-3">Cargando...</h3> : null}
            <h3 className="mb-3">Facturación mensual</h3>
            <ResponsiveContainer width="100%" height={200} >
              <BarChart
                data={pesosData}
                margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
                stackOffset="sign"
                key={Math.random()}
              >
                <CartesianGrid horizontal={false} vertical={false} />
                <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
                <YAxis
                  type="number"
                  unit="M"
                  padding={{ top: 40 }}
                  tickFormatter={(value) => DataFormatter(value)} />
                <ReferenceLine y={0} stroke="#000" />
                <Tooltip content={<CustomTooltip />} />
                <Bar dataKey="sls.d" name="Diferencia">
                  {pesosData.map((entry, index) => (
                    <Cell
                      key={index}
                      fill={entry["sls.d"] >= 0 ? "#17375e" : "#b20e0d"} />
                  ))}
                  <LabelList dataKey="sls.d" content={renderCustomLabel} />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div>
            <h3 className="my-3">Producción diaria en Kg.</h3>
            <ResponsiveContainer width="100%" height={200}>
              <BarChart
                data={pesosData}
                margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
                stackOffset="sign"
                key={Math.random()}
              >
                <CartesianGrid horizontal={false} vertical={false} />
                <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
                <YAxis
                  unit="kg"
                  type="number"
                  width={80}
                  />
                <ReferenceLine y={0} stroke="#000" />
                <Tooltip content={CustomTooltipPRD} />
                <Bar dataKey="prd.d" name="Diferencia">
                  {pesosData.map((entry, index) => (
                    <Cell
                      key={index + 100}
                      fill={entry["prd.d"] >= 0 ? "#17375e" : "#b20e0d"} />
                  ))}
                  <LabelList dataKey="prd.d" content={renderCustomLabel2} />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div><div className="m-2 m-md-4 tables">
            <table className="table">
              <thead>
                <tr>
                  <th className="text-center" scope="col">
                    Mes
                  </th>
                  <th className="text-center" scope="col">
                    Venta 2022
                  </th>
                  <th className="text-center" scope="col">
                    Venta 2023
                  </th>
                  <th className="text-center" scope="col">
                    Diferencia
                  </th>
                  <th className="text-center" scope="col">
                    Producción 2022
                  </th>
                  <th className="text-center" scope="col">
                    Producción 2023
                  </th>
                  <th className="text-center" scope="col">
                    Diferencia
                  </th>
                </tr>
              </thead>
              <tbody className="table-group-divider">{bodyTable}</tbody>
            </table>
          </div></> 
        }
    </>
  );
};

export default MegaGraph;
