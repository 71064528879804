export const request = async (route) => {

    try {
        const response = await fetch(route, {
            method: 'GET',
            headers: {'Accept': 'application/json'},
        });

        return response.json();

    } catch (error) {
        console.error('There was a problem with the fetch operation: ' + error.message);
    }
}


export const requestpost = async (route, data) => {

    try {
        const response = await fetch(route, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data),
        });

        return response.json();

    } catch (error) {
        console.error('There was a problem with the fetch operation: ' + error.message);
    }
}

export const authenticate = async (route, data) => {

  try {
    const response = await fetch(route, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(data)
    });

    if (response.ok)
      return response.json();
    else
      return false;

  } catch (error) {
    console.error('There was a problem with the fetch operation: ' + error.message);
  }
}
