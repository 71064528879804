import React, { useState, useReducer, useEffect } from 'react';
import data from './cod_nivel_economico.json';
import codNamesEconomico from './cod_names_economico.json';
import { Modal } from 'react-bootstrap';
import { requestpost } from '../Data/Requests';
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
  Tooltip,
  Legend,
} from 'recharts';
import { max, min, set } from 'date-fns';
import { Button } from 'react-bootstrap';
import './data.css';
import { ta } from 'date-fns/locale';

function ProductGraphSingle(props) {
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [productGraphUnit, setProductGraphUnit] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [nmbreProdSingle, setNmbreProdSingle] = useState('');
  // const [binProductSingle, setBinProductSingle] = useState('');
  const [percentilProductSingle, setPercentilProductSingle] = useState('');
  const [itemSelected, setItemSelected] = useState(false);
  const [codeProductSingle, setCodeProductSingle] = useState('');
  const [benefitData, setBenefitData] = useState([]);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [errorMessage, setErrorMessage] = useState(null);
  const [chimulquis, setchimulquis] = useState(0);
  const [colorExtendedProducts, setColorExtendedProducts] = useState([]);
  const [tablevisible, settablevisible] = useState(false);
  const [economicTable, setEconomicTable] = useState([]);
  const [constantInventoryValue, setConstantInventoryValue] = useState();
  const [minProd, setMinProd] = useState(0);
  // console.log('colorExtendedProducts', colorExtendedProducts);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchText(value);

    if (value === '') {
      setSearchResults([]);
      setErrorMessage(null);
      return;
    }

    const filteredResults = codNamesEconomico.filter(
      (item) =>
        item.COD_PROD.toLowerCase().includes(value.toLowerCase()) ||
        item.DESCRIPCION.toLowerCase().includes(value.toLowerCase())
    );
    setSearchResults(filteredResults);
  };

  const handleSelect = (selectedItem) => {
    const baseCode = selectedItem.COD_PROD.match(/^[^\-]+/)[0];

    const colorExtendedResults = data.filter((item) =>
      item.COD_PROD.startsWith(baseCode)
    );

    // setColorExtendedProducts(selectedItem);
    setColorExtendedProducts(colorExtendedResults);
    settablevisible(true);
    setSearchResults([]);
    setStartDate(null); // Clear previously selected date range
    setEndDate(null);
    // setItemSelected(true);
    setErrorMessage(null);
    setSearchText('');
    setSearchResults([]);
  };

  const handleSelect2 = (selectedItem2) => {
    const { COD_PROD } = selectedItem2;
    requestpost(
      `https://meg-gt.improgress.net/api/consult_graph_levels?codigos=${COD_PROD}`
    ).then((response) => {
      // Check if the response is valid and has 'eco_level'
      console.log('response graph', response);
      const isValidResponse =
        response &&
        response.length > 0 &&
        response[0].sale_data &&
        response[0].sale_data.some((item) => 'eco_level' in item) &&
        response[0].benefit_data.some((item) => 'level' in item) &&
        response[0].equation.some((item) => 'min_cost' in item);
      if (!isValidResponse) {
        setErrorMessage('No se tiene información detallada de este artículo.');
        return;
      }
      setConstantInventoryValue(response[0].inventory[0]);
      setMinProd(response[0].min_prod[0]);
      setProductGraphUnit(response[0].sale_data); //done
      setNmbreProdSingle(response[0].description); //done
      // setBinProductSingle(response[0][0].BIN);
      setPercentilProductSingle(response[0].last_percent); //done
      setCodeProductSingle(response[0].cod_prod); //done
      setBenefitData(response[0].benefit_data);
      setSearchResults([]);
      setEconomicTable(response[0].equation);
      setStartDate(null); // Clear previously selected date range
      setEndDate(null);
      setItemSelected(true);
      setErrorMessage(null);
      setSearchText('');
      setSearchResults([]);
      console.log('testingTable', economicTable);
    });
  };

  const handleNewSearch = () => {
    setItemSelected(false);
    setSearchText('');
    setSearchResults([]);
    setProductGraphUnit([]);
    setNmbreProdSingle('');
    setColorExtendedProducts([]);
    // setBinProductSingle('');
    setPercentilProductSingle('');
    setStartDate(null);
    setEndDate(null);
    settablevisible(false);
  };

  const handleClose = () => {
    setSearchText('');
    setErrorMessage(null);
    setSearchResults([]);
    props.setShow(false);
    handleNewSearch();
    settablevisible(false);
    setColorExtendedProducts([]);
  };

  const graphicSingleProduct = productGraphUnit.map((item, index) => ({
    // BIN: item.BIN,
    'Código de producto': item.COD_PROD,
    'Nombre de producto': item.DESCRIPCION,
    Fecha: item.date, //done
    Cantidad: item.quantity, //done
    Total: item.quantity_roll, //done
    // 'Min Modelo': item.nivel,
    'Min Prod': item.min_prod,
    'Min Modelo': typeof item.nivel === 'undefined' ? 0 : item.nivel,
    // 'eco level': item.eco_level || 0,
    'eco level': typeof item.eco_level === 'undefined' ? 0 : item.eco_level,
    Percentil: item.Percentil,
  }));

  const graphicSingleProduct2 = benefitData.map((item, index) => ({
    percent: item.percent,
    // level: item.level,
    level: typeof item.level === 'undefined' ? 0 : item.level,
    min_cost: item.min_cost,
    n: item.n,
    last_percent: item.last_percent,
    colorGr: item.min_cost > 0 ? '#D62727' : '#05A53C',
    inventory: item.inventory,
    min_prod: item.min_prod,
  }));

  // useEffect(() => {
  //   if (graphicSingleProduct2.length > 0) {
  //     setchimulquis(benefitData[benefitData[1].last_percent * 100 - 1].level);
  //   }
  // }, [graphicSingleProduct2]);

  useEffect(() => {
    if (
      graphicSingleProduct2.length > 0 &&
      benefitData &&
      benefitData.length > 1 &&
      typeof benefitData[1].last_percent === 'number'
    ) {
      const index = Math.round(benefitData[1].last_percent * 100) - 1;
      if (benefitData[index]) {
        setchimulquis(benefitData[index + 1].level);
      }
    }
  }, [graphicSingleProduct2]);

  // const minCostProduct = graphicSingleProduct2.filter((d) => d.min_cost <= 0);
  // const maxCostProduct = graphicSingleProduct2.filter((d) => d.min_cost > 0);

  const filteredGraphicSingleProduct = graphicSingleProduct.filter((item) => {
    if (!startDate || !endDate) {
      return true; // No date range selected, show all data
    }
    const itemDate = new Date(item.Fecha);
    return itemDate >= startDate && itemDate <= endDate;
  });

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className='custom-tooltip'
          style={{
            backgroundColor: '#fff',
            padding: '5px',
            border: '1px solid #ccc',
            borderRadius: '15px',
          }}
        >
          {/* <p className='label'>{`Fecha : ${label}`}</p>
          <p className='intro'>{`Cantidad : ${payload[0].value}`}</p>
          <p className='intro'>{`Total : ${payload[1].value}`}</p>
          <p className='intro'>{`Min Modelo : ${payload[2].value}`}</p>
          <p className='intro'>{`Eco Level : ${payload[4].value}`}</p>
          <p className='intro'>{`Min Prod : ${payload[3].value}`}</p> */}
          {payload.map((entry, index) => (
  <p key={`item-${index}`} 
     className='intro' 
     style={{ color: entry.name === 'eco level' ? 'orange' : 
     entry.name === 'Min Prod' ? 'green' : 'inherit' }}>
    
    {`${entry.name} : ${entry.value}`}
  </p>
))}
          {/* {payload.map((entry, index) => (
            <p key={`item-${index}`} className='intro'>
              {`${entry.name} : ${entry.value}`}
            </p>
          ))} */}
          
        </div>
      );
    }

    return null;
  };

  const CustomToolTip2 = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const percent = payload[0].color === payload[0].payload.colorGr ? 1 : 0;
      return (
        <div
          className='custom-tooltip'
          style={{
            backgroundColor: '#fff',
            padding: '5px',
            border: '1px solid #ccc',
            borderRadius: '15px',
          }}
        >
          <p className='label'>{`Min Cost : ${payload[percent].value}`}</p>
          <p className='intro'>{`Percent : ${payload[percent].payload.percent}`}</p>
          <p className='intro'>{`Level : ${payload[percent].payload.level}`}</p>
          <p className='intro'>{`N : ${payload[percent].payload.n}`}</p>
        </div>
      );
    }
  };

  const formatTick = (tick) => {
    const date = new Date(tick);
    const monthNames = [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear().toString().slice(-2);
    return `${month}/${year}`;
  };

  const [fullscreen, setFullscreen] = useState(true);

  const gradientOffset = () => {
    const dataMax = Math.max(...graphicSingleProduct2.map((i) => i.min_cost));
    const dataMin = Math.min(...graphicSingleProduct2.map((i) => i.min_cost));

    if (dataMax <= 0) {
      return 0;
    }
    if (dataMin >= 0) {
      return 1;
    }

    return dataMax / (dataMax - dataMin);
  };

  const off = gradientOffset();

  function formatAsCurrency(value) {
    return value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'GTQ',
    });
  }

  const tooltipStyle = {
    transform: 'scaleX(-1) rotate(270deg)',
    border: '1px solid #ccc',
    backgroundColor: 'white',
    padding: '10px',
  };

  const CustomTooltip55 = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div style={tooltipStyle}>
          <p className='label'>{`Level: ${label}`}</p>
          <p className='desc'>{`Value: ${formatAsCurrency(
            payload[0].value
          )}`}</p>
        </div>
      );
    }
    return null;
  };

  const mirroredStyle = {
    transform: 'scaleX(-1)',
    rotate: '90deg',
    fontSize: '12px',
  };

  const CustomTick2 = (props) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor='middle'
          fill='#666'
          style={mirroredStyle}
        >
          {formatAsCurrency(payload.value / 1000)}
        </text>
      </g>
    );
  };
  const mirroredStyle2 = {
    transform: 'scaleX(-1)',
    rotate: '90deg',
    border: '1px solid #ccc',
  };

  const CustomTick3 = (props) => {
    const { x, y, payload } = props;
    const offsetY = 30;
    const offsetX = 10;
    return (
      <g transform={`translate(${x + offsetX},${y + offsetY})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor='middle'
          fill='#666'
          style={mirroredStyle2}
          domain={[0, roundedMaxXValue]}
        >
          {payload.value}
        </text>
      </g>
    );
  };

  function roundUpToNearest(value, n) {
    return Math.ceil(value / n) * n;
  }

  const maxXValue = Math.max(...benefitData.map((item) => item.level));

  const roundedMaxXValue = roundUpToNearest(maxXValue, 10000);

  console.log('roundedMaxXValue', constantInventoryValue);

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        fullscreen={fullscreen}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                width: '90vw',
              }}
            >
              Búsqueda de consumo por producto:
              {!itemSelected ? (
                <>
                  <input
                    type='text'
                    placeholder='Buscar producto por código o nombre'
                    style={{ fontSize: '18px', width: '20vw' }}
                    value={searchText}
                    onChange={handleSearch}
                  />
                </>
              ) : (
                <Button onClick={handleNewSearch}>New Search</Button>
              )}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorMessage && <h1 className='error-message'>{errorMessage}</h1>}
          {!itemSelected ? (
            <>
              {searchResults.length > 0 && (
                <div style={{ height: '25vh', overflow: 'auto' }}>
                  <ul>
                    {searchResults.map((item) => (
                      <li
                        key={item.COD_PROD}
                        onClick={() => handleSelect(item)}
                      >
                        {item.COD_PROD} - {item.DESCRIPCION}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </>
          ) : null}
          {/* {itemSelected ? ( */}
          <div className='superChartTable'>
            {tablevisible ? (
              <table className='table'>
                <thead>
                  <tr>
                    <th style={{ paddingLeft: '10px' }}>CODIGO</th>
                    <th>DESCRIPCION</th>
                    <th>LEVEL</th>
                  </tr>
                </thead>
                <tbody>
                  {colorExtendedProducts.map((item, index) => (
                    <tr key={index} className='hovertext'>
                      <td>
                        <li
                          className='list-group-item22'
                          onClick={() => handleSelect2(item)}
                        >
                          {item.COD_PROD}
                        </li>
                      </td>
                      <td>{item.DESCRIPCION}</td>
                      <td>{item.LEVEL}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : null}
            {itemSelected ? (
              <div>
                <div
                  style={{
                    width: '65vw',
                    height: 680,
                    padding: '20px',
                    backgroundColor: '#f0f0f0',
                    borderRadius: '10px',
                  }}
                >
                  {itemSelected ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        width: '63vw',
                        paddingBottom: '10px',
                      }}
                    >
                      <h6>
                        <strong>Código:</strong> {codeProductSingle}{' '}
                      </h6>
                      <h6>
                        <strong>Nombre:</strong> {nmbreProdSingle}
                      </h6>
                      <div style={{ display: 'flex' }}>
                        <h6>
                          <span style={{ margin: '0 0 0 15px' }}>
                            <strong>Percentil:</strong> {percentilProductSingle}
                          </span>
                        </h6>
                      </div>
                    </div>
                  ) : null}
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginBottom: '20px',
                      backgroundColor: '#fff',
                      padding: '10px',
                      borderRadius: '10px',
                    }}
                  >
                    <h4
                      style={{
                        margin: '0 10px 0 0',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                      }}
                    >
                      Fecha de Inicio:
                    </h4>
                    <input
                      style={{
                        padding: '10px',
                        borderRadius: '5px',
                        border: '1px solid #ddd',
                      }}
                      type='date'
                      value={
                        startDate ? startDate.toISOString().split('T')[0] : ''
                      }
                      onChange={(e) =>
                        handleStartDateChange(new Date(e.target.value))
                      }
                    />
                    <h4
                      style={{
                        margin: '0 10px 0 20px',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                      }}
                    >
                      Fecha Final:
                    </h4>
                    <input
                      style={{
                        padding: '10px',
                        borderRadius: '5px',
                        border: '1px solid #ddd',
                      }}
                      type='date'
                      value={endDate ? endDate.toISOString().split('T')[0] : ''}
                      onChange={(e) =>
                        handleEndDateChange(new Date(e.target.value))
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '450px',
                      backgroundColor: '#f0f0f0',
                      borderRadius: '10px',
                    }}
                  >
                    <ComposedChart
                      width={460}
                      height={350}
                      data={filteredGraphicSingleProduct}
                      margin={{
                        top: 20,
                        right: 40,
                        bottom: 20,
                        left: 20,
                      }}
                    >
                      <CartesianGrid stroke='#f5f5f5' />
                      <XAxis
                        dataKey='Fecha'
                        style={{ fontSize: '12px' }}
                        tickFormatter={(tick) => formatTick(tick)}
                      />

                      <YAxis />
                      <Tooltip
                        content={<CustomTooltip />}
                        position={{ x: 100, y: 10 }}
                        contentStyle={{
                          fontSize: '15px',
                          padding: '5px',
                          width: '150px',
                        }}
                      />
                      <Legend />
                      <Area
                        type='monotone'
                        dataKey='Total'
                        fill='#008CFF'
                        stroke='#8884d8'
                        isAnimationActive={false}
                      />
                      <Bar dataKey='Cantidad' barSize={20} fill='#413ea0' />
                      {/* <Line
                    type='monotone'
                    dataKey='Min Modelo'
                    stroke='black'
                    strokeWidth={2}
                    dot={false}
                    isAnimationActive={false}
                  /> */}
                      <Line
                        type='monotone'
                        dataKey='Min Prod'
                        dot={false}
                        isAnimationActive={false}
                        strokeWidth={2}
                        stroke='green'
                      />
                      <Line
                        type='monotone'
                        dataKey='eco level'
                        label='Eco Level'
                        stroke='#ff7300'
                        strokeWidth={2}
                        dot={false}
                        isAnimationActive={false}
                      />
                      {/* <ReferenceLine
                        y={constantInventoryValue}
                        stroke='purple'
                        strokeWidth={5}
                      />
                      <ReferenceLine
                        y={minProd}
                        stroke='red'
                        strokeWidth={2}
                      /> */}
                      {/* <Line type="monotone" dataKey={minxxx} stroke="#ff7300" isAnimationActive={false} dot={false} /> */}
                    </ComposedChart>

                    {/* <ResponsiveContainer width='30.5%' height='95%'> */}
                    <ComposedChart
                      width={390}
                      height={400}
                      style={{ transform: 'rotate(90deg) scaleX(-1)' }}
                      data={graphicSingleProduct2}
                      margin={{
                        top: 50,
                        right: 30,
                        left: 0,
                        bottom: 50,
                      }}
                    >
                      <CartesianGrid horizontal={false} vertical={false} />
                      <XAxis
                        dataKey='level'
                        width={150}
                        tick={<CustomTick3 />}
                        domain={[0, roundedMaxXValue]}
                      />
                      <YAxis
                        height={100}
                        // angle={-180}
                        // tickFormatter={(value) => `$${value.toLocaleString()}`}
                        tick={<CustomTick2 />}
                        margin={{ top: 30, right: 60, left: 50, bottom: 50 }}
                      />
                      <Tooltip content={<CustomTooltip55 />} />
                      <defs>
                        <linearGradient
                          id='splitColor'
                          x1='0'
                          y1='0'
                          x2='0'
                          y2='1'
                        >
                          <stop
                            offset={off}
                            stopColor='green'
                            stopOpacity={1}
                          />
                          <stop
                            offset={off}
                            stopColor='#fb2222'
                            stopOpacity={2.5}
                          />
                        </linearGradient>
                      </defs>
                      <Area
                        type='monotone'
                        dataKey='min_cost'
                        stroke='#000'
                        fill='url(#splitColor)'
                      />

                      <ReferenceLine
                        x={chimulquis}
                        stroke='#ff7300'
                        strokeWidth={2}
                      />
                    </ComposedChart>
                    {/* </ResponsiveContainer> */}
                  </div>
                  <div>
                    <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '80%' }}>
                    <p style={{  padding: '0', margin: '0' }}>Inventory: {constantInventoryValue}</p>
                    <p style={{  padding: '0', margin: '0' }}>Min Prod: {minProd}</p>
                    </div>
                    <table className='table1'>
                      <thead>
                        <tr>
                          <th style={{ paddingLeft: '10px' }}>Beneficio</th>
                          <th>Margen Venta</th>
                          <th>Costo Inv</th>
                          <th>Costo Oport</th>
                          <th>Venta Perdida</th>
                        </tr>
                      </thead>
                      <tbody>
                      {economicTable.map((item, index) => (
  <tr key={index} className='ect'>
    <td style={item.min_cost < 0 ? { backgroundColor: 'red', color: 'white' } : {}}>
      {item.min_cost.toLocaleString('es-GT', {
        style: 'currency',
        currency: 'GTQ',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}
    </td>
    <td style={item.margin < 0 ? { backgroundColor: 'red', color: 'white' } : {}}>
      {item.margin.toLocaleString('es-GT', {
        style: 'currency',
        currency: 'GTQ',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}
    </td>
    <td style={item.inv < 0 ? { backgroundColor: 'red', color: 'white' } : {}}>
      {item.inv.toLocaleString('es-GT', {
        style: 'currency',
        currency: 'GTQ',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}
    </td>
    <td style={item.lost_oportunity < 0 ? { backgroundColor: 'red', color: 'white' } : {}}>
      {item.lost_oportunity.toLocaleString('es-GT', {
        style: 'currency',
        currency: 'GTQ',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}
    </td>
    <td style={item.lost_sale < 0 ? { backgroundColor: 'red', color: 'white' } : {}}>
      {item.lost_sale.toLocaleString('es-GT', {
        style: 'currency',
        currency: 'GTQ',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}
    </td>
  </tr>
))}

                      </tbody>
                    </table>
                  </div>
                  <h6 style={{ textAlign: 'end' }}>* En miles de Quetzales</h6>
                </div>
              </div>
            ) : null}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ProductGraphSingle;
