import AWS from "aws-sdk";
import swal from "sweetalert";
import XLSX from "sheetjs-style";

export const ses = new AWS.SES({
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
});

export const options = {month: "short", day: "numeric", year: "numeric"};

export const parseDate = (date) => {
    const timezone = new Date().getTimezoneOffset();
    return new Date(date.valueOf() - timezone * 60 * 1000)

}
export const parseDate2 = (date) => {
    const timezone = new Date().getTimezoneOffset();
    return new Date(date.valueOf() - timezone * 60 * 1000)
}

export const calculate_week = (actual_date, current = true) => {
    const day = actual_date.getDay()

    let actual;
    if (current)
        actual = actual_date.getDate() - day + (day === 0 ? -6 : 1)
    else
        actual = actual_date.getDate() - (day + (day === 0 ? -6 : 1) + 5);

    const week = new Date(actual_date.setDate(actual))
    const offset = week.getTimezoneOffset() / 60
    week.setHours((0 - offset), 0, 0)
    return week
}


export const send_email = (to, body, subject, sender, error_msg, success_msg) => {
    const params = {
        Destination: {ToAddresses: to},
        Message: {
            Body: {
                Text: {
                    Data: body,
                },
            },
            Subject: {
                Data: subject,
            },
        },
        Source: sender,
    };

    ses.sendEmail(params, async (error, data) => {
        if (error) {
            console.log(error)
            await swal(error_msg, "", "error");
        } else await swal(success_msg, "", "success").then(function () {
            window.location.reload();
        })
    });
}


export const data2body = (data) => {
    return `Quisiera solicitar vacaciones desde ${data.start_date} hasta ${data.end_date}. 
    \n Mi capitán ${data.captain} ${data.entered ? 'ya se encuentra informado sobre mi ausencia' : 'no se encuentra informado sobre mi ausencia'}. En mis proyecto(s) ${data.clients.join(', ')} me cubrirá(n) ${data.cover.join(', ')}, quien(es) ${data.entered_cover ? 'ya se encuentra(n) enterado(s)' : 'aún no se encuentra(n) enterado(s)'}. Nuestro cliente será informado sobre mis vacaciones por el ${data.notify}. El motivo de mis vacaciones es: ${data.reason}`;
};


const ExportToExcel = (type, fn, dl) => {
    var elt = document.getElementById("resume_table");
    var wb = XLSX.utils.table_to_book(elt, {sheet: "sheet1"});
    XLSX.writeFile(wb, fn || "resume_table." + (type || "xlsx"));
    //return dl ?
    //    XLSX.write(wb, { bookType: type, bookSST: true, type: 'base64' }):
    //    XLSX.writeFile(wb, fn || ('resume_table.' + (type || 'xlsx')));
}
